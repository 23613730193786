
// import React from 'react';
// import { X } from 'lucide-react';

// const Modal = ({ image, onClose }) => {
//     if (!image) return null;

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
//             <div className="relative bg-white p-4 rounded-lg max-w-full max-h-full">
//                 <img
//                     src={image}
//                     alt="Popup"
//                     className="max-w-full max-h-[80vh] object-contain"
//                 />
//                 <button
//                     onClick={onClose}
//                     className="absolute top-2 right-2 bg-white p-2 rounded-full text-black focus:outline-none"
//                 >
//                     <X size={24} />
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default Modal;

import React from 'react';
import { X } from 'lucide-react';

const Modal = ({ image, onClose }) => {
    if (!image) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
            <div className="relative bg-white p-4 rounded-lg max-w-full max-h-full mx-2 sm:mx-4 lg:mx-8">
                <img
                    src={image}
                    alt="Popup"
                    className="max-w-full max-h-[80vh] object-contain"
                />
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 bg-white p-2 rounded-full text-black focus:outline-none"
                >
                    <X size={24} />
                </button>
            </div>
        </div>
    );
};

export default Modal;
