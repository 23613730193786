// import React, { Component } from 'react';
// import { useState } from 'react';

// const OurMedia =()=> {
//     const [currentSlide, setCurrentSlide] = useState(0);
//     const slides = [
//         "/Assets/AboutUsBanner5.png",
//         "/Assets/AboutUsBanner2.png",
//         "/Assets/AboutUsBanner3.png",
//         "/Assets/AboutUsBanner4.png",
//         "/Assets/AboutUsBanner1.png",

//     ];

//     const goToSlide = (index) => {
//         setCurrentSlide(index);
//     };

//     const goToPrevSlide = () => {
//         setCurrentSlide(prevSlide => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
//     };

//     const goToNextSlide = () => {
//         setCurrentSlide(prevSlide => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
//     };
      
//         return (
//             <>
//                 {/* New section for both mobile and large screens */}
//                 {/* Larger screens */}
//                 <div className="hidden md:flex flex-col py-10 px-16">
//                     <div className="flex flex-row items-center mb-8">
//                         <p className="w-1/3 font-medium">Our Media Coverages</p>
//                         <div className="border-t border-gray-300 flex-grow"></div>
//                     </div>
//                     <div className="flex justify-between space-x-4 gap-1">
//                         <a href="https://hindi.news18.com/news/rajasthan/kota-leaving-the-package-of-rs-30-lakh-in-mnc-started-duper-health-startup-7278423.html">
//                             <img src="/Assets/AboutUsBanner1.png" alt="news 18 india logo" className='w-1/1' />
//                         </a>
//                         <a href="/Assets/abhi.jpg">
//                             <img src="/Assets/daniklogo.png" alt="dainik bhaskar logo" className='w-1/1' />
//                         </a>
//                         <a href="/Rajasthanpatrika.png">
//                             <img src="/Assets/AboutUsBanner3.png" alt="rajasthan patrika logo" className='w-1/1 mt-2' />
//                         </a>
//                         <a href="/Assets/URBANHERALD1.png">
//                             <img src="/Assets/UHbanner.png" alt="JITO logo" className='w-1/1' />
//                         </a>
                       
//                         <a href="/Assets/terajasthan.jpg">
//                             <img src="/Assets/AboutUsBanner4.png" alt="TIE Rajasthan logo" className='w-1/1' />
//                         </a>
//                         <a href="/Assets/news18.jpg">
//                             <img src="/Assets/AboutUsBanner5.png" alt="news 18 logo" className='w-1/1' />
//                         </a>
//                         <a href="https://yourstory.com/companies/dooper">
//                             <img src="/Assets/Yourstorybanner.png" alt="awsstartup" className='w-1/1 '/>
//                         </a>
//                         {/* <a href="/Assets/URBANHERALD1.png">
//                             <img src="/Assets/Awsbsp.png" alt="news 18 logo" className='w-1/1' />
//                         </a> */}
                        
//                     </div>
//                 </div>
//             </>
//         );
//     }

// export default OurMedia;

// import React, { useState } from 'react';
// import Modal from './Modal'; // Import the Modal component

// const OurMedia = () => {
//   const [modalImage, setModalImage] = useState(null); // State for the modal image

//   const banners = [
//     { bannerSrc: "/Assets/AboutUsBanner1.png",link: "https://hindi.news18.com/news/rajasthan/kota-leaving-the-package-of-rs-30-lakh-in-mnc-started-duper-health-startup-7278423.html" },
//     { bannerSrc: "/Assets/daniklogo.png", modalImage: "/Assets/abhi.jpg",  },
//     { bannerSrc: "/Assets/AboutUsBanner3.png", modalImage: "/Rajasthanpatrika.png" },
//     { bannerSrc: "/Assets/UHbanner.png", modalImage: "/Assets/URBANHERALD1.png"},
//     { bannerSrc: "/Assets/AboutUsBanner4.png", modalImage: "/Assets/terajasthan.jpg"},
//     { bannerSrc: "/Assets/AboutUsBanner5.png", modalImage: "/Assets/news18.jpg" },
//     { bannerSrc: "/Assets/Yourstorybanner.png",link: "https://yourstory.com/companies/dooper" },
//   ];

//   const handleImageClick = (modalImage) => {
//     setModalImage(modalImage); // Set the modal image
//   };

//   const handleLinkClick = (link) => {
//     window.open(link, '_blank', 'noopener,noreferrer'); // Open the link in a new tab
//   };

//   const handleCloseModal = () => {
//     setModalImage(null); // Close the modal
//   };

//   return (
//     <>
//       {/* New section for both mobile and large screens */}
//       {/* Larger screens */}
//       <div className="hidden sm:flex sm:flex-col sm:py-4 sm:px-6 md:py-8 md:px-12 lg:py-10 lg:px-16 xl:py-12 xl:px-20">
//         <div className="flex flex-row items-center mb-8">
//           <p className="w-1/3 font-medium">Our Media Coverages</p>
//           <div className="border-t border-gray-300 flex-grow"></div>
//         </div>
//         <div className="flex justify-between space-x-2 ">
//           {banners.map((item, index) => (
//             <img
//               key={index}
//               src={item.bannerSrc}
//               alt={`media ${index}`}
//               className='w-1/1 cursor-pointer'
//               onClick={() => item.link ? handleLinkClick(item.link) : handleImageClick(item.modalImage)}
//             />
//           ))}
//         </div>
//       </div>

//       {/* Modal for image display */}
//       <Modal image={modalImage} onClose={handleCloseModal} />
//     </>
//   );
// };

// export default OurMedia;

import React, { useState } from 'react';
import Modal from './Modal'; // Import the Modal component

const OurMedia = () => {
  const [modalImage, setModalImage] = useState(null); // State for the modal image

  const banners = [
    { bannerSrc: "/Assets/AboutUsBanner1.png", link: "https://hindi.news18.com/news/rajasthan/kota-leaving-the-package-of-rs-30-lakh-in-mnc-started-duper-health-startup-7278423.html" },
    { bannerSrc: "/Assets/daniklogo.png", modalImage: "/Assets/abhi.jpg" },
    { bannerSrc: "/Assets/AboutUsBanner3.png", modalImage: "/Rajasthanpatrika.png" },
    { bannerSrc: "/Assets/UHbanner.png", modalImage: "/Assets/URBANHERALD1.png" },
    { bannerSrc: "/Assets/AboutUsBanner4.png", modalImage: "/Assets/terajasthan.jpg" },
    { bannerSrc: "/Assets/AboutUsBanner5.png", modalImage: "/Assets/news18.jpg" },
    { bannerSrc: "/Assets/jitologo.png", link: "https://www.linkedin.com/posts/prachirberiwal_healthcareinnovation-quickcare-superdooperhealth-activity-7151962369210900480-nms2?utm_source=share&utm_medium=member_ios" },
    { bannerSrc: "/Assets/Yourstorybanner.png", link: "https://yourstory.com/companies/dooper" },
  ];

  const handleImageClick = (modalImage) => {
    setModalImage(modalImage); // Set the modal image
  };

  const handleLinkClick = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer'); // Open the link in a new tab
  };

  const handleCloseModal = () => {
    setModalImage(null); // Close the modal
  };

  return (
    <>
      {/* New section for both mobile and large screens */}
      <div className="hidden md:flex flex-col py-10 px-16">
        <div className="flex flex-row items-center mb-6 md:mb-8">
          <p className="w-full md:w-1/3 font-medium">Our Media Coverages</p>
          <div className="border-t border-gray-300 flex-grow"></div>
        </div>
        <div className="grid grid-cols-8 sm:grid-cols-8 md:grid-cols-8 lg:grid-cols-8 gap-2">
          {banners.map((item, index) => (
            <img
              key={index}
              src={item.bannerSrc}
              alt={`media ${index}`}
              className='w-full cursor-pointer'
              onClick={() => item.link ? handleLinkClick(item.link) : handleImageClick(item.modalImage)}
            />
          ))}
        </div>
      </div>

      {/* Modal for image display */}
      <Modal image={modalImage} onClose={handleCloseModal} />
    </>
  );
};

export default OurMedia;
