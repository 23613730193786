const TestimonialCard = ({img, name, content}) => {
    return (
        // <div className=" w-[340px] h-[340px] rounded-[10px] border-[1px] border-[#EEF0F3] p-[10px] bg-[#FFFFFF] max-w-[350px] mx-auto mb-[40px] relative h-auto ">
            
        //     <div className="border-b-[1px] p-[10px] border-b-[#EEF0F3] flex items-center -mt-12">
        //         <img src={img} alt="userImage"  className="w-[60px] mt-12 h-auto contener " />
        //         <div className="ml-[15px] ">
        //             <p className="text-[18px] font-medium mt-12 ">{name}</p>
        //             <img src="/Assets/Stars.png" alt="stars" className="w-[110px] mt-[5px] " />
        //         </div>
        //     </div>
        //     <div className="text-[#1A1C1F] p-[10px] leading-[28.8px] text-[16px]  ">
        //         <p>{content}</p>
        //     </div>
        //     {/* <div className="w-full" style={{ paddingBottom: '50.25%' }}></div> */}
        // </div>
        
        <div className="rounded-[10px] border-[1px] border-[#EEF0F3] bg-[#FFFFFF] max-w-[350px] mx-auto mb-[40px] relative overflow-hidden h-full ">
    {/* <div className="absolute inset-0 flex flex-col p-[10px]"> */}
        <div className="border-b-[1px] p-[10px] border-b-[#EEF0F3] flex items-center">
            <img src={img} alt="userImage" className="w-[60px]" />
            <div className="ml-[15px]">
                <p className="text-[18px] font-medium">{name}</p>
                <img src="/Assets/Stars.png" alt="stars" className="w-[110px] mt-[5px]" />
            </div>
        </div>
        <div className="text-[#1A1C1F] p-[10px] leading-[28.8px] text-[16px] flex-grow">
            <p>{content}</p>
        {/* </div> */}
    </div>
    {/* <div className="w-full" style={{ paddingBottom: '56.25%' }}></div> */}
</div>
    );
}

export default TestimonialCard;

// import React, { useState } from 'react';

// const TestimonialCard = ({ img, name, content }) => {
//   const [expanded, setExpanded] = useState(false);

//   const words = content.split(' ');
//   const isLongContent = words.length > 50;

//   const displayContent = isLongContent && !expanded ? words.slice(0, 50).join(' ') + '...' : content;

//   return (
//     <div className="testimonial-card">
//       <img src={img} alt={`${name}'s picture`} className="testimonial-img" />
//       <p className="testimonial-name">{name}</p>
//       <p className="testimonial-content">{displayContent}</p>
//       {isLongContent && (
//         <button onClick={() => setExpanded(!expanded)} className="view-more-btn">
//           {expanded ? 'View Less' : 'View More'}
//         </button>
//       )}
//     </div>
//   );
// };

// export default TestimonialCard;
