// // working scroll without links

// import React from "react";
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { useState } from "react";
// import { Link } from "react-router-dom";


// const certificateDetails = [
//     { img: "/Assets/AboutUsBanner5.png", alt: "news 18 india logo", newslink: "https://hindi.news18.com/news/rajasthan/kota-leaving-the-package-of-rs-30-lakh-in-mnc-started-duper-health-startup-7278423.html" },
//     { img: "/Assets/daniklogo.png", alt: "dainik bhaskar logo", newslink: "/Assets/abhi.jpg" },
//     { img: "/Assets/AboutUsBanner4.png", alt: "TIE Rajasthan logo", newslink: "/Assets/terajasthan.jpg" },


//     { img: "/Assets/YourStory.png", alt: "your story logo", newslink: "https://yourstory.com/companies/dooper" },
//     { img: "/Assets/UH.png", alt: "your story logo", newslink: "/Assets/URBANHERALD1.png" },

    
//     // { img: "/Assets/AboutUsBanner2.png", alt: "JITO logo",newslink: "https://www.linkedin.com/posts/prachirberiwal_healthcareinnovation-quickcare-superdooperhealth-activity-7151962369210900480-nms2/?utm_source=share&utm_medium=member_desktop" },
//     { img: "/Assets/AboutUsBanner1.png", alt: "news 18 logo", newslink: "https://hindi.news18.com/news/rajasthan/kota-effect-of-cold-increased-in-education-city-room-heaters-installed-change-in-diet-of-students-also-7912080.html" },
//     { img: "/Assets/blank.png", alt: "news 18 logo", newslink: "https://hindi.news18.com/news/rajasthan/kota-effect-of-cold-increased-in-education-city-room-heaters-installed-change-in-diet-of-students-also-7912080.html" },
    
//     { img: "/Assets/AboutUsBanner3.png", alt: "rajasthan patrika logo", newslink: "Rajasthanpatrika.png" },
    
    
// ];

// const OurMediaMobile = () => {
//     const [dot1, setDot1] = useState(true);
//     const [dot2, setDot2] = useState(false);
//     const [dot3, setDot3] = useState(false);
//     const [certificateSelectedDot, setCertificateSelectedDot] = useState(1);

//     return (
//         <>
//             {/* *********************************************mobile our media********************** */}

//             <div className="md:hidden flex flex-col items-center py-[30px] px-[20px] ">
//             <div className="flex justify-center mt-[40px]">
//                     <p className="bg-[#F5F6F7] text-center px-[10px] py-[3px] rounded-[5px] text-[#1A1C1F] w-[190px] font-semibold text-[14px]">Our Achievements</p>
//                 </div>
//                 <p className="text-center text-[24px] mt-[10px] font-medium">Our Media Coverage</p>
//                 <div className="w-full mt-[10px]">
//                     <Carousel
//                         showArrows={false}
//                         showStatus={false}
//                         showThumbs={false}
//                         showIndicators={false}
//                         autoPlay
//                         interval={3000}
//                         infiniteLoop
//                         selectedItem={certificateSelectedDot - 1}
//                         onChange={(index) => setCertificateSelectedDot(index + 1)}
//                     >
                       
//                     </Carousel>
//                     <div className="grid  grid-cols-3 lg:grid-cols-3 lg:gap-3 justify-items-cente gap-6 justyfy " >
//                     {
//                             certificateDetails.map((certificate, index) => (
//                                 <div key={index} className="flex justify-center">
//                                    <a href={certificate.newslink}>
//                                     <img src={certificate.img} alt={certificate.alt} className="" />
//                                     </a>
//                                 </div>
//                             ))
//                         }
//                         </div>
//                 </div>
//                 {/* <div className='flex space-x-2 justify-center mt-[20px]'>
//                     {certificateDetails.map((_, index) => (
//                         certificateSelectedDot === index + 1
//                             ? <img key={index} src="/Assets/ActiveDot.png" className='w-[25px]' alt="active dot for scrolling" />
//                             : <img
//                                 key={index}
//                                 onClick={() => setCertificateSelectedDot(index + 1)}
//                                 src="/Assets/InactiveDot.png"
//                                 className='w-[10px] h-[10px] cursor-pointer'
//                                 alt="inactive dot for scrolling"
//                             />
//                     ))}
//                 </div> */}
//             </div>
//         </>
//     )
// }

// export default OurMediaMobile;



import React, { useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Modal from './Modal'; // Import the Modal component

const certificateDetails = [
    { bannerSrc: "/Assets/AboutUsBanner1.png", link: "https://hindi.news18.com/news/rajasthan/kota-leaving-the-package-of-rs-30-lakh-in-mnc-started-duper-health-startup-7278423.html" },
    { bannerSrc: "/Assets/daniklogo.png", modalImage: "/Assets/abhi.jpg" },
    { bannerSrc: "/Assets/AboutUsBanner3.png", modalImage: "/Rajasthanpatrika.png" },
    { bannerSrc: "/Assets/UHbanner.png", modalImage: "/Assets/URBANHERALD1.png" },
    { bannerSrc: "/Assets/AboutUsBanner4.png", modalImage: "/Assets/terajasthan.jpg" },
    { bannerSrc: "/Assets/AboutUsBanner5.png", modalImage: "/Assets/news18.jpg" },
    { bannerSrc: "/Assets/jitologo.png", alt: "news 18 logo",link: "https://www.linkedin.com/posts/prachirberiwal_healthcareinnovation-quickcare-superdooperhealth-activity-7151962369210900480-nms2?utm_source=share&utm_medium=member_ios" },
    { bannerSrc: "/Assets/Yourstorybanner.png", link: "https://yourstory.com/companies/dooper" },
];

const OurMediaMobile = () => {
    const [modalImage, setModalImage] = useState(null); // State for the modal image
    const [certificateSelectedDot, setCertificateSelectedDot] = useState(1);

    const handleImageClick = (imageSrc) => {
        setModalImage(imageSrc); // Set the modal image
    };

    const handleLinkClick = (link) => {
        window.open(link, '_blank', 'noopener,noreferrer'); // Open the link in a new tab
    };

    const handleCloseModal = () => {
        setModalImage(null); // Close the modal
    };

    return (
        <>
            <div className="md:hidden flex flex-col items-center py-[30px] px-[20px]">
                <div className="flex justify-center mt-[40px]">
                    <p className="bg-[#F5F6F7] text-center px-[10px] py-[3px] rounded-[5px] text-[#1A1C1F] w-[190px] font-semibold text-[14px]">Our Achievements</p>
                </div>
                <p className="text-center text-[24px] mt-[10px] font-medium">Our Media Coverage</p>
                <div className="w-full mt-[10px]">
                    <Carousel
                        showArrows={false}
                        showStatus={false}
                        showThumbs={false}
                        showIndicators={false}
                        autoPlay
                        interval={3000}
                        infiniteLoop
                        selectedItem={certificateSelectedDot - 1}
                        onChange={(index) => setCertificateSelectedDot(index + 1)}
                    />
                    <div className="grid grid-cols-3 lg:grid-cols-3 lg:gap-3 justify-items-center gap-6">
                        {certificateDetails.map((certificate, index) => (
                            <div key={index} className="flex justify-center">
                                {certificate.link ? (
                                    <a href={certificate.link} target="_blank" rel="noopener noreferrer">
                                        <img src={certificate.bannerSrc} alt={certificate.alt || "Media logo"} className="cursor-pointer" />
                                    </a>
                                ) : (
                                    <img
                                        src={certificate.bannerSrc}
                                        alt={certificate.alt || "Media logo"}
                                        className="cursor-pointer"
                                        onClick={() => handleImageClick(certificate.modalImage)}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Modal for image display */}
            {modalImage && <Modal image={modalImage} onClose={handleCloseModal} />}
        </>
    );
};

export default OurMediaMobile;

