// export const IndividualTests = [
//     {
//         name: "Full Body Checkup - Essential",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
//     {
//         name: "Full Body Checkup - Advanced",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
//     {
//         name: "Full Body Checkup - Comprehensive",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
//     {
//         name: "STD Test Package - Essential",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
//     {
//         name: "Vitamin Package",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
//     {
//         name: "Full Body Checkup - Basic",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
//     {
//         name: "Women Health Checkup - Comprehensive",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
//     {
//         name: "Fever Profile - Advanced",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
//     {
//         name: "Diabetes Care Package - Advanced",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
//     {
//         name: "STD Test Package - Advanced",
//         tests: 63,
//         testList: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"],
//         price: "429",
//         oldPrice:"",
//         discount: ""
//     },
// ];

// export default IndividualTests;
export const IndividualTests = 
[
    
           
    {
      "name": "Hemogram",
      "testList": ["Cbc With Esr"],
      "price": "400"
  },
  {
      "name": "Sugar Test , Glucose , Fasting , Plasma",
      "testList": ["Sugar Test","Fasting","Glucose","Plasma"],
      "price": "70"
  },
  {
      "name": "Sugar PP",
      "testList": ["Sugar Test","Post-Prandial","Glucose","Plasma"],
      "price": "70"
  },
  {
      "name": "Heart Assure (High-Sensitive Cardiac Troponin I)",
      "testList": ["High-Sensitive Cardiac Troponin I"],
      "price": "1820"
  },
  {
      "name": "Anti-Islet Cell Antibodies",
      "testList": ["Anti Islet Cell Antibody With Titre"],
      "price": "4100"
  },
  {
      "name": "Bactec Blood Culture (Aerobic & Aerobic)",
      "testList": ["Bactec Blood Culture (Aerobic & Aerobic)"],
      "price": "2400"
  },
  {
      "name": "Anti Islet Cell Antibody",
      "testList": ["Anti Islet Cell Antibody"],
      "price": "2900"
  },
  {
      "name": "Anti GBM Antibod",
      "testList": ["Anti Glomeruil Of Kidney (Gbm)"],
      "price": "1950"
  },
  {
      "name": "Autoimmune Hepatitis Profile 1",
      "testList": ["Ana","Asma","Lkmabs","Total Igg"],
      "price": "5000"
  },
  {
      "name": "Apolipoprotein A1/B Ratio , Serum",
      "testList": [],
      "price": "1600"
  },
  {
      "name": "Urinalysis, Urine R/M , Routine",
      "testList": ["Urinalysis"],
      "price": "90"
  },
  {
      "name": "Creatinine , Serum",
      "testList": ["Creatinine","Serum"],
      "price": "150"
  },
  {
      "name": "Rti Prognosis Panel (Without Il-06)",
      "testList": ["Cbc-5","Albumin","Creatinine","Troponine-1","D-Dimer","Lactate Dehydrogenase","Aspartate Aminotransferase","Prothrombin Time","Procalcitonin","C-Reactive Protein","Ferritin","Total","Bilirubin","Alanine Aminotranfere"],
      "price": "7990"
  },
  {
      "name": "Thyroid Panel , Serum",
      "testList": ["T3","T4","T5"],
      "price": "580"
  },
  {
      "name": "Alanine Aminotransferase , Serum",
      "testList": ["Alanine Aminotransferase","Serum"],
      "price": "250"
  },
  {
      "name": "Liver Function Profile",
      "testList": ["Ast (Sgot)","Alt(Sgpt)","Alkaline Phosphate","Ggt","Ldh","Allbumin","Globulin","A:G Ratio","Serum Protein Total","Bilirubin Total & Direct"],
      "price": "1100"
  },
  {
      "name": "Coronary Risk Profile (Lipid Profile)",
      "testList": ["Triglycerides","Ldl","Vldl","Hdl","Cholesterol Total/Hdl Ratio","Cholesterol Total","Ldl/Hdl Ratio"],
      "price": "830"
  },
  {
      "name": "Prothrombin Time , Plasma",
      "testList": ["Prothrombin Time"],
      "price": "400"
  },
  {
      "name": "Uric Acid , Serum",
      "testList": ["Uric Acid"],
      "price": "250"
  },
  {
      "name": "Hiv Screening 4 Th Gen Assay , Serum",
      "testList": [],
      "price": "680"
  },
  {
      "name": "Glycosylated Hemoglobin",
      "testList": ["Hba1c","Glycosylated Hemoglobin"],
      "price": "620"
  },
  {
      "name": "Aspartate Aminotransferase , Serum",
      "testList": ["Aspartate Aminotransferase","Serum"],
      "price": "230"
  },
  {
      "name": "Hepatitis B Surface Antigen , Serum",
      "testList": [],
      "price": "600"
  },
  {
      "name": "Hepatitis C Antibodies , Serum",
      "testList": ["Hcv Abs"],
      "price": "1200"
  },
  {
      "name": "C Reactive Protein , Serum (Quantitative)",
      "testList": ["Crp"],
      "price": "590"
  },
  {
      "name": "Elecrolyes(Na/K/Cl) Serum",
      "testList": ["Elecrolyes(Na/K/Cl) Serum"],
      "price": "550"
  },
  {
      "name": "Calcium , Serum",
      "testList": ["Calcium","Serum"],
      "price": "250"
  },
  {
      "name": "Stool : Ova & Parasite",
      "testList": ["Stool : Ova & Parasite"],
      "price": "220"
  },
  {
      "name": "Serum Blood Urea Nitrogen",
      "testList": ["Serum Blood Urea Nitrogen"],
      "price": "240"
  },
  {
      "name": "Liver & Kidney Profile",
      "testList": ["Ast(Sgot)"],
      "price": "1600"
  },
  {
      "name": "Hcg , Serum",
      "testList": ["Hcg","Serum"],
      "price": "800"
  },
  {
      "name": "Prostage Specific Antigen Serum",
      "testList": ["Prostate Specific Antigen (Psa) Total"],
      "price": "950"
  },
  {
      "name": "Rheumatoid Factor Quantitative , Serum",
      "testList": ["Rheumatoid Factor Antibodies"],
      "price": "660"
  },
  {
      "name": "Glucose Random , Plasma",
      "testList": ["Glucose Random","Plasma"],
      "price": "90"
  },
  {
      "name": "Abo Group & Rh Type , Edta Whole Blood",
      "testList": ["Abo Group & Rh Type"],
      "price": "260"
  },
  {
      "name": "Prolactin , Serum",
      "testList": ["Prolactin"],
      "price": "580"
  },
  {
      "name": "Insulin , Fasting Serum",
      "testList": ["Insulin","Fasting Serum"],
      "price": "1000"
  },
  {
      "name": "Kidney Panel 1",
      "testList": ["Urinalysis","Uric Acid","Bun","Creatinine","Bun/Creatinine Ratio","Total Protein","Albumin","Globulin","Electrolytes"],
      "price": "965"
  },
  {
      "name": "Culture , Urine With Susceptibility",
      "testList": ["Culture","Urine With Susceptibility"],
      "price": "1100"
  },
  {
      "name": "Total Iron Binding Capacity , Serum",
      "testList": ["Total Iron Binding Capacity"],
      "price": "550"
  },
  {
      "name": "Bilirubin Total",
      "testList": ["Bilirubin (Total, Direct, Indirect)","Serum"],
      "price": "300"
  },
  {
      "name": "Total Ige , Serum",
      "testList": ["Total Ige"],
      "price": "1150"
  },
  {
      "name": "Vitamin B12 Level , Serum",
      "testList": ["Vitamin B12 Level"],
      "price": "1200"
  },
  {
      "name": "Feriitin , Serum",
      "testList": ["Feriitin"],
      "price": "980"
  },
  {
      "name": "Fever Panel Mini",
      "testList": ["Cbc","Esr","Urinalysis","Malarial Parasite(M.P)","Alanine Aminotransferese(Sgpt)"],
      "price": "860"
  },
  {
      "name": "Malarial Parasite(M.P),",
      "testList": ["Edta Whole Blood"],
      "price": "280"
  },
  {
      "name": "Malaria Antigen Detection , Whole Blood",
      "testList": ["Malaria Antigen (P. Falciparum/P.Vivax) Detection"],
      "price": "700"
  },
  {
      "name": "Anti-Ccp Antibodies , Serum",
      "testList": ["Anti - Cyclic Citrullinated Peptide Antibodies (Anti-Ccp)"],
      "price": "1700"
  },
  {
      "name": "Testosterone , Total , Serum",
      "testList": ["Testosterone","Total"],
      "price": "800"
  },
  {
      "name": "Vdrl , Serum",
      "testList": ["Vdrl","Serum"],
      "price": "300"
  },
  {
      "name": "Anti Tpo Antibodies",
      "testList": ["Anti-Thyroid Peroxidase Antibodies","Serum"],
      "price": "1280"
  },
  {
      "name": "Tyroid Antibodies , Serum",
      "testList": ["Anti- Thyroid Preoxidase Abs. And Anti-Thyroglobulin Abs"],
      "price": "2200"
  },
  {
      "name": "Thyroid Panel 2 , Serum",
      "testList": ["Ft3","Ft4","Tsh3g-Ul"],
      "price": "770"
  },
  {
      "name": "Afb Bactec Culture",
      "testList": ["Acid Fast Bacilli Culture: Mgit"],
      "price": "1650"
  },
  {
      "name": "Microalbuminuria ,Urine",
      "testList": ["Microalbuminuria","Urine"],
      "price": "650"
  },
  {
      "name": "Folic Acid , Serum",
      "testList": ["Folic Acid"],
      "price": "1350"
  },
  {
      "name": "Tissu Transglutaminase Lga , Serum",
      "testList": ["Tissu Transglutaminase Lga","Ttg"],
      "price": "1600"
  },
  {
      "name": "Dengue Ns1 Antigen Test",
      "testList": ["Dengue Ns1 Antigen Test"],
      "price": "990"
  },
  {
      "name": "Anti Nuclear Ab Ifa , Hep2 Serrum",
      "testList": ["Ana"],
      "price": "1100"
  },
  {
      "name": "Hemoglobin By Cyanmethemoglobin",
      "testList": ["Hemoglobin & Hematocrit By Cyanmethaemoglobin"],
      "price": "195"
  },
  {
      "name": "Lh,Fsh,Prolactin , Serum",
      "testList": ["Follicle Stimulating Hormone","Luteinizing Hormone Evaluation & Prolactin (Lh","Fsh & Prl)"],
      "price": "1650"
  },
  {
      "name": "Progesteron , Serum",
      "testList": ["Progesteron"],
      "price": "650"
  },
  {
      "name": "Estradiol , Serum",
      "testList": ["Estradiol"],
      "price": "670"
  },
  {
      "name": "Rapid Typhi Igm",
      "testList": ["Rapid Typhi Igm","Serum/Plasma Edta/Edta"],
      "price": "700"
  },
  {
      "name": "Tropical Fever Panel Extended By Pcr",
      "testList": ["P.Vivax","P.Falciparum","P.Ovale","P.Malariae","Salmonella Spp Leptospira","Dengue","Chikungunya","Zika","Jev","Wnv Andrickettsia"],
      "price": "6500"
  },
  {
      "name": "Acute Liver Panel By Pcr",
      "testList": ["P.Vivax","P.Falciparum","P.Ovale","P.Malariae","Salmonella Spp Leptospira","Dengue","Chikungunya"],
      "price": "5000"
  },
  {
      "name": "Ferver Panel Basic",
      "testList": ["Cbc","Uranalysis","Esr","Widal Test","Smear For Malarial Parasite(M.P)"],
      "price": "860"
  },
  {
      "name": "Panfungal Detection Panal",
      "testList": ["Homocysteine"],
      "price": "1400"
  },
  {
      "name": "Cortisol , Serum",
      "testList": ["Cortisol","Serum"],
      "price": "800"
  },
  {
      "name": "Sodium , Serum",
      "testList": ["Sodium","Serum"],
      "price": "225"
  },
  {
      "name": "Stachybotrys Atra",
      "testList": ["Stachybotrys Atra IgG Ab"],
      "price": "1930"
  },
  {
      "name": "Total Iron Binding Capacity , Serum",
      "testList": ["Total Iron Binding Capacity","Serum"],
      "price": "550"
  },
  {
      "name": "Total Protein , Serum",
      "testList": ["Total Protein","Serum"],
      "price": "225"
  },
  {
      "name": "Triglycerides , Serum",
      "testList": ["Triglycerides"],
      "price": "270"
  },
  {
      "name": "Urinary Protein Creatinine Ratio",
      "testList": ["Protein Creatinine Ratio"],
      "price": "500"
  },
  {
      "name": "Viral Screen",
      "testList": ["Hiv-1&2 Antibodies","Hbsag","Vdrl"],
      "price": "1395"
  },
  {
      "name": "Phosphorus , Serum",
      "testList": ["Phosphorus ","Serum"],
      "price": "225"
  },
  {
      "name": "Potassium , Serum",
      "testList": ["Potassium ","Serum"],
      "price": "250"
  },
  {
      "name": "Saag For Ascitic Fluid",
      "testList": ["Ascitic Fluid"],
      "price": "400"
  },
  {
      "name": "Saccharopolyspora Rectivirgula",
      "testList": ["Saccharopolyspora Rectivirgula IgG Ab"],
      "price": "1930"
  },
  {
      "name": "Iron , Serum",
      "testList": ["Iron"],
      "price": "485"
  },
  {
      "name": "Lactate Dehydrogenase , Serum",
      "testList": ["Lactate Dehydrogenase"],
      "price": "325"
  },
          
  {
    "name": "Liver Function Test (Without GGT)",
    "testList": [
        "AST (SGOT)",
        "ALT (SGPT)",
        "Alkaline Phosphatase",
        "Bilirubin Total & Direct LDH",
        "Albumin",
        "Globulin A:G Ratio",
        "Serum Protein Total"
    ],
    "price": "860"
},
{
    "name": "Hcv Abs, Serum",
    "testList": [
        "Hcv Abs"
    ],
    "price": "1150"
},
{
    "name": "Hepatitis B Surface Antigen, Serum",
    "testList": [
        "Hepatitis B Surface Antigen (Serum)"
    ],
    "price": "535"
},
{
    "name": "Creatine Kinase (Cpk), Serum",
    "testList": [
        "Creatine Kinase (Cpk) (Serum)"
    ],
    "price": "460"
},
{
    "name": "Creatine Kinase-Mb, Serum",
    "testList": [
        "Ck Mb",
        "Creatine Kinase"
    ],
    "price": "700"
},
{
    "name": "Creatinine Egfr",
    "testList": [
        "Gfr Calculation"
    ],
    "price": "345"
},
{
    "name": "Dual Marker (Fmf) By Trf Method, Serum",
    "testList": [
        ""
    ],
    "price": "2650"
},
{
    "name": "Dengue Duo Rapid Screening Test, Serum",
    "testList": [
        "Dangue Rapid"
    ],
    "price": "1900"
},
{
    "name": "Diabeta Screen",
    "testList": [
        "Glycosylated Hemoglobin",
        "Glucose F And Pp"
    ],
    "price": "680"
},
{
    "name": "Fatty Liver Index",
    "testList": [
        "Tg",
        "Ggt",
        "Bmi",
        "Weight",
        "Height",
        "Waist Circumference"
    ],
    "price": "590"
},
        
  {
    "name": "Cbc-5,Edta Whole Blood",
    "testList": ["Cbc-5", "Edta Whole Blood"],
    "price": "320"
  },
  {
    "name": "Cholesterol , Serum",
    "testList": ["Cholesterol", "Serum"],
    "price": "235"
  },
  {
    "name": "Ante-Natal Panel",
    "testList": ["Cbc", "Glucose Fasting", "Tsh", "Abo Grouping & Rh Typing", "Vdrl", "Urinalysis", "Hbsag", "Urinalysis"],
    "price": "2570"
  },
  {
    "name": "Acid Fast Bacilli Smear",
    "testList": ["Mycobacteria Detection"],
    "price": "405"
  },
  {
    "name": "Albumin , Serum",
    "testList": ["Albumin", "Serum"],
    "price": "225"
  },
  {
    "name": "Tsh 3G Ultra",
    "testList": ["Thyroid Stimulating Hormone", "3rd Generation Ultra (Tsh3g - Ul)"],
    "price": "380"
  },
  {
    "name": "Vitamin D , 25-Hydroxyvitamin D , Serum",
    "testList": ["Vitamin D (25-Hydroxy Vitamin D)"],
    "price": "1700"
  },
  {
    "name": "Mycoral",
    "testList": ["Tb Pcr (Mycoreal)"],
    "price": "2550"
  },
  {
    "name": "Amh",
    "testList": ["Amh (Anti-Müllerian Hormone)/ Müllerian Inhibiting Substance (MIS)"],
    "price": "2200"
  },
  {
    "name": "Vitamin D And B12 , Vitamin Plus",
    "testList": ["25-Oh Vit D And Vit B12"],
    "price": "1750"
  },
  {
    "name": "Protein Electrophoresis",
    "testList": ["Protein Electrophoresis"],
    "price": "965"
  },
  {
    "name": "Rubella Igg & Igm , Serum",
    "testList": ["Rubella Igg & Igm Antibodies"],
    "price": "1555"
  },
  {
    "name": "Quadruple Marker",
    "testList": ["Afp", "Hcg", "E3un &Dia"],
    "price": "3300"
  },
  {
    "name": "Identification - Gram Negative Organism",
    "testList": ["Identification - Gram Negative Organism"],
    "price": "1180"
  },
  {
    "name": "Quantitative D-Dimer",
    "testList": ["D-Dimer"],
    "price": "1600"
  },
  {
    "name": "High Sensitive C-Reactive Protein",
    "testList": ["High Sensitive C-Reactive Protein (HsCRP)"],
    "price": "800"
  },
  {
    "name": "Medium Biopsy Specimen , Tissu",
    "testList": ["Medium Biopsy Specimen, Tissu"],
    "price": "2000"
  },
  {
    "name": "Xpert Mtb /Rif Ultra – Extra-Pulmonary",
    "testList": ["Xpert Mtb /Rif Ultra – Extra-Pulmonary"],
    "price": "2416"
  },
  {
    "name": "Mycobacterium Culture - Bactec Extra Pulmonary",
    "testList": ["Individual Test"],
    "price": "1650"
  },
  {
    "name": "Hla-B27, Flow Cytometry , Blood",
    "testList": ["Hla B27 (Flowcytometry)"],
    "price": "2400"
  },
  {
    "name": "Hepattis B E Antigen , Serum",
    "testList": ["Hepattis B E Antigen", "Serum"],
    "price": "1125"
  },
  {
    "name": "Carcino Embryonic Antigen , Serum",
    "testList": ["Cea"],
    "price": "935"
  },
  {
    "name": "C3 (Complement Protein Concentration)",
    "testList": ["C3"],
    "price": "880"
  },
  {
    "name": "Follicle Stimulating Hormone , Serum",
    "testList": ["Follicle Stimulating Hormone (Fsh)"],
    "price": "550"
  },
  {
    "name": "Xpert Mtb/Rif Ultra-Pulmonary",
    "testList": ["Xpert Mtb/Rif Ultra-Pulmonary"],
    "price": "2416"
  },
  {
    "name": "Triple Test",
    "testList": ["Afp, Hcg & E3un"],
    "price": "2750"
  },
  {
    "name": "Allergen-Aspergillusfumgatus , Serum",
    "testList": ["Aspergillus IgE"],
    "price": "1500"
  },
  {
    "name": "Fecal Calprotectin , Stool",
    "testList": ["Fecal Calprotectin", "Stool"],
    "price": "3000"
  },
  {
    "name": "Angiotensin Converting Enzyme , Serum",
    "testList": ["Angiotensin Converting Enzyme"],
    "price": "1400"
  },
  {
    "name": "Biopsy (Large Tissu /Specimen)",
    "testList": ["Biopsy"],
    "price": "4815"
  },
          
  {
    "name": "Lupus Anticoagulant",
    "testList": ["Lupus Anticoagulant Screening Profile"],
    "price": "2300"
  },
  {
    "name": "Luteinizing Hormone , Serum",
    "testList": ["Lh (Luteinizing Hormone)"],
    "price": "550"
  },
  {
    "name": "C4(Complement Protein Concentration)",
    "testList": ["C4(Complement Protein Concentration)"],
    "price": "880"
  },
  {
    "name": "Hepatitis B Core Total Abs",
    "testList": ["Hepatitis B Antibodies" ,"Serum"],
    "price": "1235"
  },
  {
    "name": "Free Thyroxine(Ft4) , Serum",
    "testList": ["Free Thyroxine(Ft4)"],
    "price": "330"
  },
  {
    "name": "Hepattis B Core Total Abs",
    "testList": ["Hepattis B Core Antibodies" ,"Srum"],
    "price": "985"
  },
  {
    "name": "Hbc Viral Load By Real Time Pcr",
    "testList": ["Hbc Viral Load By Real Time Pcr"],
    "price": "4300"
  },
  {
    "name": "Erythro Sedimentation Rate , Blood",
    "testList": ["Esr"],
    "price": "170"
  },
  {
    "name": "Return Of Praffin Block/Slide",
    "testList": ["Return Of Praffin Block/Slide"],
    "price": "215"
  },
  {
    "name": "G-6-Pd , Quantitative , Blood",
    "testList": ["G-6-Pd" ,"Quantitative" ,"Blood"],
    "price": "1150"
  },
  {
    "name": "Scrub Typhus Igm Serum",
    "testList": ["Scrub Typhus Igm Antibody"],
    "price": "1180"
  },
  {
    "name": "Ca 19-9 , Serum",
    "testList": ["Ca 19-9"],
    "price": "1415"
  },
  {
    "name": "Anca",
    "testList": ["Anca (Anti Neutrophil Cytoplasmic Antibodies) (P-Anca + C-Anca) With Titre (Reflex To End Titre To All Positive Cases)"],
    "price": "2450"
  },
  {
    "name": "Breast Evaluation Panel",
    "testList": ["Er","Pr","Her2/Neu"],
    "price": "3535"
  },
  {
    "name": "Dsdna (With Titres )",
    "testList": ["Dsdna (Reflex To End Titre For All Positive Cases)"],
    "price": "1700"
  },
  {
    "name": "Free Testosterone , Serum",
    "testList": ["Testesterone" ,"Free"],
    "price": "1900"
  },
  {
    "name": "1,25 Dihdroxy Vitamin D , Serum",
    "testList": ["1,25 Dihdroxy Vitamin D"],
    "price": "4280"
  },
  {
    "name": "Adenosine Deaminase",
    "testList": ["Adenosine Deaminase (Ada)"],
    "price": "1020"
  },
  {
    "name": "Nt-Probnp",
    "testList": ["N-Terminal Pro B-Type Natriuretic Peptide", "Sreum"],
    "price": "3000"
  },
  {
    "name": "Zinc , Serum",
    "testList": ["Zinc","Serum"],
    "price": "2195"
  },
  {
    "name": "Intact Pth",
    "testList": ["Pth","Intact  Including Total Calcium"],
    "price": "1900"
  },
  {
    "name": "Dehydroepiandrosterone-S-Serum",
    "testList": ["Dehydroepiandrosterone-Sulfate (Dheas)"],
    "price": "1350"
  },
  {
    "name": "Hbv Quantitative By Cobas Taqman",
    "testList": ["Hbv Quantitative By Cobas Taqman"],
    "price": "7000"
  },
  {
    "name": "Hla B27 By Pcr",
    "testList": ["Hla B27 (Pcr)"],
    "price": "3600"
  },
  {
    "name": "T.Pallidum Hemagglutinatin , Serum",
    "testList": ["Treponema Pallidium Hemagglutination Assay (Tpha)"],
    "price": "880"
  },
  {
    "name": "C Peptide Serum",
    "testList": ["C Peptide Serum"],
    "price": "1350"
  },
  {
    "name": "Bcr/Abl - Quantitative (Philadelphia Chromosome)",
    "testList": ["Bcr/Abl Quatification By Is"],
    "price": "7225"
  },
  {
    "name": "Chikungunya IgM Antibodies Rapid , Serum",
    "testList": ["Chikungunya Igm - Rapid"],
    "price": "1070"
  },
  {
    "name": "Magnesium , Serum",
    "testList": ["Magnesium", "Serum"],
    "price": "580"
  },
  {
    "name": "Blood Lympo Culture",
    "testList": ["Blood Lympo Culture"],
    "price": "4300"
  },
  {
    "name": "Galactomannan , Serum",
    "testList": ["Galactomannan","Serum"],
    "price": "5700"
  },
  {
    "name": "Ceruloplasmin",
    "testList": ["Ceruloplasmin"],
    "price": "1340"
  },
  {
    "name": "Antiphospholipid Igg Antiboies",
    "testList": ["Antiphospholipid Igg Antiboies"],
    "price": "1125"
  },
  {
    "name": "Antiphospholipid Igm Antobody , Serum",
    "testList": ["Anti Phospholipid Igm Antibodies"],
    "price": "1125"
  },
  {
    "name": "Cardiolipin Antibodies , Serum",
    "testList": ["Cardiolipin Igg & Igm Antibodies"],
    "price": "1930"
  },
  {
    "name": "Dengue Virus Igm , Serum",
    "testList": ["Dengue Virus Igm Antibodies"],
    "price": "965"
  },
  {
    "name": "Leptospira Igmserum",
    "testList": ["Leptospira Igm Antibodies"],
    "price": "1300"
  },
  {
    "name": "Osmolality , Urine",
    "testList": ["Osmolality","Urine"],
    "price": "1125"
  },
  {
    "name": "Vitamin C , Edta Plasma",
    "testList": ["Cephalosporin","Serum"],
    "price": "3535"
  },
  {
    "name": "Holotc(Holotranscobalamine)",
    "testList": ["Holotranscobalamine(Active Vit B12)"],
    "price": "1395"
  },
  {
    "name": "Vitamin E Serum /Edta Plasma",
    "testList": ["Aspirin","Serum"],
    "price": "4280"
  },
  {
    "name": "Karyotyping Hematological Disorders",
    "testList": ["Karyotyping"],
    "price": "5885"
  },
  {
    "name": "Psa Free And Total  , Serum",
    "testList": ["Prostate Specific Antigen (Psa) Free & Total"],
    "price": "1730"
  },
  {
    "name": "Tyroglobulin Serum",
    "testList": ["Thyroglobulin"],
    "price": "1605"
  },
  {
    "name": "Adrenocorticotropic Hormone , Plasma",
    "testList": ["Adrenocorticotropic Hormone","Plasma"],
    "price": "1770"
  },
  {
    "name": "Insulin Like Growth Factor -I , Serum",
    "testList": ["Insulin Like Growth Factor-1 (Igf-1)"],
    "price": "3150"
  },
  {
    "name": "Vitamin A , Serum/ Edta Plasma",
    "testList": ["Flexi Allergy Panel (Any 5 Allergens)"],
    "price": "4280"
  },
  {
    "name": "Testesterone , Free /Total,Serum",
    "testList": ["Testosterone","Free/Total"],
    "price": "2500"
  },
  {
    "name": "Cd4/Cd8",
    "testList": ["Cd4/Cd8 (Lymphocyte Enumeration STudy-T Cells (%Cd3,%Cd4,%Cd8,ABS Cd3,ABS Cd4,ABS Cd8))"],
    "price": "1930"
  },
  {
    "name": "Free Triodothyronine(Ft3) , Serum",
    "testList": ["Free Triiodothyronine","T3"],
    "price": "315"
  },
  {
    "name": "Acetyl Cholin Receptor Antibodies",
    "testList": ["Acetyl Cholin Receptor Antibodies"],
    "price": "3640"
  },
  {
    "name": "Hepatitis B Surfae Abs (Quant) , Serum",
    "testList": ["Hepatitis B Surfae Abs (Quant)" ,"Serum"],
    "price": "1125"
  },
  {
    "name": "Hepatitis A Igm , Serum",
    "testList": ["Hepatitis A Igm" ,"Serum"],
    "price": "1250"
  },
         
  {
    "name": "Interleukin 6 , serum",
    "testList": ["IL-6 (Interluekin-6)"],
    "price": "3425"
  },
  {
    "name": "Vitamin D plus",
    "testList": ["25-OH Vit D","Phosphorus","Calcium","PTH Intact"],
    "price": "1930"
  },
  {
    "name": "Lipase serum",
    "testList": ["Lipase"],
    "price": "780"
  },
  {
    "name": "Androstenedione , serum",
    "testList": ["Androstenedione"],
    "price": "2090"
  },
  {
    "name": "HIV 1viral load by real time PCR",
    "testList": ["HIV 1viral load by real time PCR"],
    "price": "4280"
  },
  {
    "name": "Fungus culture and stain",
    "testList": ["Fungus culture", "Stain and identification"],
    "price": "1400"
  },
  {
    "name": "Rubella IgM avidity , serum",
    "testList": ["Rubella IgM avidity"],
    "price": "1605"
  },
  {
    "name": "Growth hormone serum",
    "testList": ["Growth hormone (GH)"],
    "price": "985"
  },
  {
    "name": "Lithium",
    "testList": ["Amino acids","Random urine"],
    "price": "645"
  },
  {
    "name": "Selenium , serum",
    "testList": ["Selenium", "Serum"],
    "price": "3800"
  },
  {
    "name": "Troponin I , serum",
    "testList": ["Troponin I"],
    "price": "1900"
  },
  {
    "name": "Helicobactor pylori antigen detection",
    "testList": ["Helicobactor pylori antigen detection"],
    "price": "1605"
  },
  {
    "name": "CD4",
    "testList": ["CD4 (Lymphocyte subset percentage and absolute count)"],
    "price": "1200"
  },
  {
    "name": "Hepatitis A IgM , serum",
    "testList": ["Hepatitis A IgM" ,"Serum"],
    "price": "1650"
  },
  {
    "name": "Amylase , serum",
    "testList": ["Amylase serum"],
    "price": "720"
  },
  {
    "name": "Total IgM , serum",
    "testList": ["IgM"],
    "price": "775"
  },
  {
    "name": "Prenat-next(NIPS)",
    "testList": ["NIPT"],
    "price": "16000"
  },
  {
    "name": "Osmolality , serum",
    "testList": ["Osmolality","Serum"],
    "price": "1095"
  },
  {
    "name": "Metanephrines",
    "testList": ["Metanephrines"],
    "price": "3320"
  },
  {
    "name": "Lipoprotein (A) , serum",
    "testList": ["Lipoprotein (A)", "Serum"],
    "price": "1250"
  },
  {
    "name": "Protein S activity , plasma",
    "testList": ["Protein S activity"],
    "price": "4390"
  },
  {
    "name": "Copper , serum",
    "testList": ["Copper", "Serum"],
    "price": "2140"
  },
  {
    "name": "Total bile acids",
    "testList": ["Total bile acids"],
    "price": "2035"
  },
  {
    "name": "Torch IgM & IgM evaluation , serum",
    "testList": ["Torch IgG & IgM antibodies evaluation (9 parameters)"],
    "price": "3425"
  },
  {
    "name": "Dihrotestesterone , serum",
    "testList": ["Dihydrotestosterone (DHT)"],
    "price": "2650"
  },
  {
    "name": "17-hydroxyprogesterone , serum",
    "testList": ["17-Alpha-hydroxyprogesterone"],
    "price": "1770"
  },
  {
    "name": "Insulin , serum(post-prandial)",
    "testList": ["Insulin","Serum (Post-prandial)"],
    "price": "1000"
  },
  {
    "name": "Erythropoietin , serum",
    "testList": ["Erythropoietin ", "Serum"],
    "price": "2380"
  },
  {
    "name": "HCV viral load by real time PCR",
    "testList": ["HCV viral load by real time PCR"],
    "price": "7665"
  },
  {
    "name": "Thyroscreen panel",
    "testList": ["FT4 & TSH3G - UL"],
    "price": "700"
  },
  {
    "name": "Hepatitis B core IgM , serum",
    "testList": ["Hepatitis B core IgM", "Serum"],
    "price": "1200"
  },
  {
    "name": "Indirect coombs test , serum",
    "testList": ["Coombs indirect test"],
    "price": "775"
  },
  {
    "name": "Breast CA panel",
    "testList": ["ER","PR","HER2/Neu","KL-67"],
    "price": "4605"
  },
  {
    "name": "Comprehensive allergy panels-adults",
    "testList": ["Comprehensive allergy panel adult (Phadia top adult list of s includemilk soybean cheese almond coconut wheat egg white chicken cod fish shrimp tuna salmon D.ptteronyssinus D.farinae cockroach house dust Greer mugwort English plantain lambs quarter alder birch cat epithelium dog dander cladoprorium aspergillus candida common ragweed cultivated rye velvet)"],
    "price": "15000"
  },
  {
    "name": "Anti thyroglobulin antibodies , serum",
    "testList": ["Anti-thyroglobulin antibodies (aTG)"],
    "price": "1600"
  },
  {
    "name": "Chikungunya IgM antibody , serum",
    "testList": ["Chikungunya IgM"],
    "price": "1210"
  },
  {
    "name": "Homeostatic model assessment(homa)2",
    "testList": [],
    "price": "1285"
  },
  {
    "name": "Glutamic acid decarboxylase IgG",
    "testList": ["Glutamic acid decarboxylase IgG antibodies","Serum ( GAD -65)"],
    "price": "6420"
  },
  {
    "name": "Couple karytyping(pblc)",
    "testList": ["Couple karytyping(pblc)"],
    "price": "5350"
  },
  {
    "name": "Biotidinase",
    "testList": ["Inhibin B"],
    "price": "2355"
  },
  {
    "name": "Fibrinosen , plasma",
    "testList": ["Fibrinogen level"],
    "price": "1095"
  },
  {
    "name": "Antismooth muscle abs (ifa) , serum",
    "testList": ["Anti smooth muscle antibodies"],
    "price": "2200"
  },
  {
    "name": "Sex hormone binding globulin , serum",
    "testList": ["Sex hormone binding globulin (SHBG)"],
    "price": "2355"
  },
  {
    "name": "Lead ,  blood",
    "testList": ["Lead",  "Blood"],
    "price": "2250"
  },
        
          {
            "name": "Anti Phospholipase A2 Receptor(PLA2R) IgG",
            "testList": ["Anti-Phospholipase A2 Receptor (Pla2R) IgG", "Serum"],
            "price": "5140"
          },
          
        {
          "name": "EPSTEIN BARR VIRUS NUCLEAR ANTIGEN IgG",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "PROTEIN C ACTIVITY , PLASMA",
          "testList": [],
          "price": "4390"
        },
        {
          "name": "CULTURE,SPUTUM SUSCEPTIBILITY",
          "testList": [],
          "price": "1100"
        },
        {
          "name": "BETA-2 MICROGLOBULIN",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "CHROMOGRANIN A , SERUM",
          "testList": [],
          "price": "7280"
        },
        {
          "name": "OLIGOCLONAL BANDS , CSF",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "COPPER 24 HOURS , URINE",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "TACROLIMUS",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "LEPTOSPIRA IgG , serum",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "PLASMA METANEPHRINE FREE (PLASMA)",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "HERPEX SIMPLEX VIRUS DNA DETECTOR",
          "testList": [],
          "price": "4390"
        },
        {
          "name": "HER 2/Neu by FISH",
          "testList": [],
          "price": "14980"
        },
        {
          "name": "ANTITHROMBIN ACTIVITY , PLASMA",
          "testList": [],
          "price": "4605"
        },
        {
          "name": "IgG4 , SERUM",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "ANTICARDIOLIPIN ANTIBODIES IgM , SERUM",
          "testList": [],
          "price": "860"
        },
        {
          "name": "HELICOBACTOR PYLORI IgM ANTIBODIES",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "FLU REAL TIME PCR",
          "testList": [],
          "price": "5500"
        },
        {
          "name": "CMV VIRAL LOAD REAL TIME PCR",
          "testList": [],
          "price": "7705"
        },
        {
          "name": "BCR/ABL IS-INTERNATIONAL SCALE",
          "testList": [],
          "price": "7225"
        },
        {
          "name": "ANTICARDIOLIPIN ANTIBODIES IgM , SERUM",
          "testList": [],
          "price": "965"
        },
        {
          "name": "HSV  1& 2 IgG AND IgM , SERUM",
          "testList": [],
          "price": "2090"
        },
        {
          "name": "RUBELLA IgG ANTIBODIES , SERUM",
          "testList": [],
          "price": "805"
        },
        {
          "name": "TRANSFERRIN SERUM",
          "testList": [],
          "price": "1200"
        },
        {
          "name": "TROPONIN T-HS , SERUM",
          "testList": [],
          "price": "1950"
        },
        {
          "name": "JAK2 V617F MUTATION DETECTION",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "NMO-MOG ANTIBODIES",
          "testList": [],
          "price": "8025"
        },
        {
          "name": "IgA",
          "testList": [],
          "price": "775"
        },
        {
          "name": "IDENTIFICATION-GRAM POSITIVE ORGANISM",
          "testList": [],
          "price": "1180"
        },
        {
          "name": "ANTIGLOMERULAR BASEMENT",
          "testList": [],
          "price": "1980"
        },
        {
          "name": "FACTOR V MUTATION DETECTION",
          "testList": [],
          "price": "6210"
        },
        {
          "name": "ANTISTREPTOLYSIN-O , SERUM",
          "testList": [],
          "price": "610"
        },
        {
          "name": "SPERM DNA FRAGMENTATION",
          "testList": [],
          "price": "5000"
        },
        {
          "name": "MDR TB RAPIDGENETYPIC TEST",
          "testList": [],
          "price": "1930"
        },
        {
          "name": "PRAFFIN BLOCK H&ESLIDE , TISSU PB",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "PROCALCITONIN",
          "testList": [],
          "price": "2700"
        },
        {
          "name": "SECOND OPINION /REVIEW",
          "testList": [],
          "price": "8455"
        },
        {
          "name": "VARICELLA ZOSTER IgG , SERUM",
          "testList": [],
          "price": "1980"
        },
        {
          "name": "RAAS SCREENING",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "FUNGAL STAIN",
          "testList": [],
          "price": "535"
        },
        {
          "name": "IHC(1)",
          "testList": [],
          "price": "2035"
        },
        {
          "name": "CYTOMEGALOVIRUS IgG&IgM , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "IMMUNOFIXATION EP , SERUM",
          "testList": [],
          "price": "8350"
        },
        {
          "name": "HPV DNA DETECTOR",
          "testList": [],
          "price": "2945"
        },
        {
          "name": "HIV 1 VIRAL LOAD",
          "testList": [],
          "price": "5800"
        },
        {
          "name": "HEPATITIS A ANTIBODIES , SERUM",
          "testList": [],
          "price": "1020"
        },
        {
          "name": "TOTAL T4 , SERUM",
          "testList": [],
          "price": "250"
        },
        {
          "name": "TOXOPLASMA ANTIBODIES , SERUM",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "APOLIPOPROTEIN B , SERUM",
          "testList": [],
          "price": "645"
        },
        {
          "name": "HISTOPATHOLOGY REVIEW",
          "testList": [],
          "price": "7385"
        },
        {
          "name": "RETICULOCYTE COUNT , EDTA WHOLE BLOOD",
          "testList": [],
          "price": "375"
        },
        {
          "name": "TOTAL T3 , SERUM",
          "testList": [],
          "price": "270"
        },
        {
          "name": "WEIL-FELIX TEST , SERUM",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "IgM",
          "testList": [],
          "price": "805"
        },
        {
          "name": "C DIFFICILE TOXIN A/B",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "CA 15-3 , SERUM",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "HIV ABS WESTERN BLOT , SERUM",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "VANILMANDELIC ACID , 24HR URINE",
          "testList": [],
          "price": "3800"
        },
        {
          "name": "ANTIMITOCHONDRIAL ANTIBODIES , AMA",
          "testList": [],
          "price": "1980"
        },
        {
          "name": "HEPATITIS B E ANTIBODY , SERUM",
          "testList": [],
          "price": "1125"
        },
        {
          "name": "TRYPTASE , SERUM",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "PML RA RA  T(15:17) , QUANTITATIVE",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "JC /BK DNA PCR",
          "testList": [],
          "price": "6210"
        },
        {
          "name": "FRUCTOSAMINE",
          "testList": [],
          "price": "1180"
        },
        {
          "name": "CHIKUNGUNYA RNA PCR",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "EGFR MUTATION DETECTION",
          "testList": [],
          "price": "6315"
        },
        {
          "name": "HEP B EVALUATION",
          "testList": [],
          "price": "1820"
        },
        {
          "name": "URINARY OXALATE",
          "testList": [],
          "price": "4000"
        },
        {
          "name": "VITAMIN B6,SERUM / EDTA  PLASMA",
          "testList": [],
          "price": "3640"
        },
        {
          "name": "ALDOSTERONE ,  SERUM",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "HCV GENOTYPING",
          "testList": [],
          "price": "8560"
        },
        {
          "name": "AUTOIMMUNE ENCEPHALITIS PANEL",
          "testList": [],
          "price": "21935"
        },
        {
          "name": "BCR-ABL T(9;22) BY FISH",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "HAV IgG ANTIBODY",
          "testList": [],
          "price": "1235"
        },
        {
          "name": "PHOTO , IHC , TISSU/PRAFFIN BLOCK",
          "testList": [],
          "price": "325"
        },
        {
          "name": "PLASMA RENIN",
          "testList": [],
          "price": "5460"
        },
        {
          "name": "HBsAG QUANT",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "ALLERGEN PHADIATOP ADULT , SERUM",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "DENGU VIRUS IgG , SERUM",
          "testList": [],
          "price": "910"
        },
        {
          "name": "BCR/ABL (QUALITATIVE)",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "HLA-A B DR LOCI",
          "testList": [],
          "price": "20330"
        },
        {
          "name": "FREE PSA , SERUM",
          "testList": [],
          "price": "1020"
        },
        {
          "name": "HAPTOGLOBULIN , SERUM",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "ANTI AQUAPORIN-4(NMO-IgG) ANTIBODIES",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "DIGOXIN (LANOXIN)",
          "testList": [],
          "price": "1125"
        },
        {
          "name": "HEPATITIS C VIRUS RNA QUANTITATIVE",
          "testList": [],
          "price": "9365"
        },
        {
          "name": "SICKLING TEST , BLOOD",
          "testList": [],
          "price": "505"
        },
        {
          "name": "PERIPHERAL SMEA REXAM , EDTA WHOLE BLOOD",
          "testList": [],
          "price": "315"
        },
        {
          "name": "MULTIPLE MYELOMA PANEL",
          "testList": [],
          "price": "14445"
        },
        {
          "name": "ALK-1 FOR LUNG TUMORS BY IHC VENTANA",
          "testList": [],
          "price": "4925"
        },
        {
          "name": "CRYPTOCOCCUS ANTIGEN , CSF",
          "testList": [],
          "price": "2945"
        },
        {
          "name": "EPSTEIN BARR VIRUS ANTIGEN IgM",
          "testList": [],
          "price": "1770"
        },
        {
          "name": "FIBRIOSIS-4 SCORE",
          "testList": [],
          "price": "270"
        },
        {
          "name": "HCV RNA QUALITATIVE PCR",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "PNH TEST ON WHITE BLOOD CELLS",
          "testList": [],
          "price": "7385"
        },
        {
          "name": "POC-GENETIC SCREENING",
          "testList": [],
          "price": "7500"
        },
        {
          "name": "DEHYDROEPIANDROSTERONE(DHEA)",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "HEPATITIS E VIRUS IgG antibodies",
          "testList": [],
          "price": "1555"
        },
        {
          "name": "COOMBS TEST , DIRECT , BLOOD",
          "testList": [],
          "price": "700"
        },
        {
          "name": "CYTOMEGALOVIRUS IgG , SERUM",
          "testList": [],
          "price": "775"
        },
        {
          "name": "HERPES SIMPLEX VIRUS-2 IgM serum",
          "testList": [],
          "price": "775"
        },
        {
          "name": "PREOPERATIVE PANEL",
          "testList": [],
          "price": "1770"
        },
        {
          "name": "MISMATCH REPAIR GENE(IHC)",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "ABCD3",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "CYCLOSPOINE,EDTA/HEPARIN WHOLE BLOOD",
          "testList": [],
          "price": "3265"
        },
        {
          "name": "CITRATE , URINE 24-H ENZYMATIC",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "GASTRIN , SERUM",
          "testList": [],
          "price": "1735"
        },
        {
          "name": "NOR-METANEPHRINE , ELISA , URINE (24HOURS URINE)",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "CALCITONIN (THYROCALCITONIN) , SERUM",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "BILIRUBIN , TOTAL , SERUM",
          "testList": [],
          "price": "225"
        },
        {
          "name": "MUSK ANTIBODY(SERUM EIA)",
          "testList": [],
          "price": "6635"
        },
        {
          "name": "CULTURE , STOOL AEROBIC-(C&S)",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "EPSTEIN BARR VIRUS ANTIGEN IgG",
          "testList": [],
          "price": "1930"
        },
        {
          "name": "CYSTANIN  C(QUANTITATIVE) ,  SERUM",
          "testList": [],
          "price": "1100"
        },
        {
          "name": "TPMT GENOTYPING",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "G-6-P-D ACTIVITY , WHOLE BLOOD",
          "testList": [], 
          "price": "965"
        },
        {
          "name": "GRAM STAIN",
          "testList": [],
          "price": "430"
        },
        {
          "name": "HELICOBACTOR PYLORI IgG ANTIBODIES",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "HEMOGLOBIN HEMATOCRIT",
          "testList": [],
          "price": "185"
        },
        {
          "name": "ANTI INSULIN ANTIBODIES , SERUM",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "ENTAMOEBA HISTOLYTICA ANTIBODIES , SERUM",
          "testList": [],
          "price": "1555"
        },
        {
          "name": "CDC CROSSMATCH",
          "testList": [],
          "price": "3855"
        },
        {
          "name": "ALLERGEN-MILK , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "CRYOGLOBULIN , SERUM",
          "testList": [],
          "price": "1235"
        },
        {
          "name": "TECROLIMUS , EDTA/HEPARIN WHOLE BLOOD",
          "testList": [],
          "price": "4550"
        },
        {
          "name": "APOLIPOPROTEIN -A1 , SERUM",
          "testList": [],
          "price": "645"
        },
        {
          "name": "VITAMIN-B1 SERUM/EDTA PLASMA",
          "testList": [],
          "price": "3640"
        },
        {
          "name": "NEUROTROPIC VIRUS PANEL",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "RETINOL BINDING PROTEIN , SERUM",
          "testList": [],
          "price": "2785"
        },
        {
          "name": "SELENIUM ,  BLOOD",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "TNTRINSIC FACTOR IgM , serum",
          "testList": [],
          "price": "2465"
        },
        {
          "name": "KIDNET PANEL 2",
          "testList": [],
          "price": "2035"
        },
        {
          "name": "LIVER-KIDNEY-MICRO ABS , SERUM",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "NEONATALKARYOTYPING(0-1 MONTH)",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "FREE ANDROGEN INDEX SERUM",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "ECHINOCOCCUS IgG , SERUM",
          "testList": [],
          "price": "1715"
        },
        {
          "name": "EPSTEIN BARR VIRUS NUCLEAR ANTIGEN IgM",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "FSH AND LH EVALUATION , SERUM",
          "testList": [],
          "price": "1180"
        },
        {
          "name": "FACTOR 8 ACTIVITY , PLASMA",
          "testList": [],
          "price": "2785"
        },
        {
          "name": "B-ACUTE LYMPHOBLASTIC LEUKEMIA MRD",
          "testList": [],
          "price": "11235"
        },
        {
          "name": "SODIUM , URINE",
          "testList": [],
          "price": "270"
        },
        {
          "name": "VITAMIN K1 , SERUM",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "POC",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "DPD GENE MUTATIONS",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "FIBRINOGEN DEGRADATION PRODUCTS (FDP)",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "CATECHOLAMINES , URINE",
          "testList": [],
          "price": "5140"
        },
        {
          "name": "CHROMIUM , SERUM",
          "testList": [],
          "price": "3640"
        },
        {
          "name": "CYTOMEGALOVIRUS IgG AVIDITY , SERUM",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "TOXOPLASMA IgM SERUM",
          "testList": [],
          "price": "750"
        },
        {
          "name": "ROMA-HE4+CA125",
          "testList": [],
          "price": "3105"
        },
        {
          "name": "ROS1",
          "testList": [],
          "price": "3320"
        },
        {
          "name": "RAPID KALAZAR TEST , SERUM",
          "testList": [],
          "price": "1930"
        },
        {
          "name": "RECIPTENT ANTIBODY STATUS(POS/NEG)",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "ALDOLASE , SERUM",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "CYTOMEGALOVIRUS DNA DETECTOR",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "B12 PLUS",
          "testList": [],
          "price": "1735"
        },
        {
          "name": "BRUCELLA IgM ANTIBODIES , SERUM",
          "testList": [],
          "price": "1340"
        },
        {
          "name": "ABS EOSINOPHILCOUNT , EDTA WHOLE BLOOD",
          "testList": [],
          "price": "225"
        },
        {
          "name": "HSV  1& 2  IgM ABS COMBINED",
          "testList": [],
          "price": "985"
        },
        {
          "name": "TOTAL PROTEIN , ALBUNIN, GLOBULIN, SERUM",
          "testList": [],
          "price": "290"
        },
        {
          "name": "VARICELLA ZOSTER IgM , SERUM",
          "testList": [],
          "price": "1980"
        },
        {
          "name": "NSE(NEURON SPECIFIC ENOLASE)",
          "testList": [],
          "price": "3855"
        },
        {
          "name": "CRYPTOCOCCUS ANTIGEN , SERUM",
          "testList": [],
          "price": "2165"
        },
        {
          "name": "BK VIRUS DNA QUANTITATIVE",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "ALLERGEN - WHEAT , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "COMPREHENSIVE ALLERGY -INFANCY , SERUM",
          "testList": [],
          "price": "11235"
        },
        {
          "name": "PLATELET COUNT , EDTA WHOLE BLOOD",
          "testList": [],
          "price": "195"
        },
        {
          "name": "PREGNANCY TEST , URINE",
          "testList": [],
          "price": "295"
        },
        {
          "name": "KIDNEY BIOPSY NATIVE",
          "testList": [],
          "price": "4925"
        },
        {
          "name": "FREE BETA HCG , SERUM",
          "testList": [],
          "price": "985"
        },
        {
          "name": "GLUCOSE , URINE",
          "testList": [],
          "price": "90"
        },
        {
          "name": "HLA-A , B , C , DRB1 , DQ1 , DPB1 HR LOCI TYPING",
          "testList": [],
          "price": "20330"
        },
        {
          "name": "ANTI-VGKC ANTIBODIES , CSF/ SERUM",
          "testList": [],
          "price": "7705"
        },
        {
          "name": "BRUCELLA IgG and IgM ANTIBODIES , SERUM",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "cd-19 PERSENT",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "CLOSTRIDIUM DIFFICILE REAL TIME",
          "testList": [],
          "price": "3535"
        },
        {
          "name": "ANTI-NMDA RECEPTOR ENCEPHALITIS IgG ABS",
          "testList": [],
          "price": "6635"
        },
        {
          "name": "HTLV 1&2 ANTIBODIES",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "Y-CHROMOSOME MICRODELETION PCR",
          "testList": [],
          "price": "9095"
        },
        {
          "name": "PCOS ADVANCE PANEL",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "PARAFFIN BLOCKS/H&E SLIDE , TISSU/PB",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "PHENYTOIN , SERUM",
          "testList": [],
          "price": "1125"
        },
        {
          "name": "FLEXI ALLERGY PANEL(ANY 5 ALLERGENS)",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "CYTOMEGALOVIRUS IgM , SERUM",
          "testList": [],
          "price": "750"
        },
        {
          "name": "FILARIA ANTIBODIES , SERUM",
          "testList": [],
          "price": "1180"
        },
        {
          "name": "FLEXI ALLERGY PANEL(ANY 10 ALLERGENS)",
          "testList": [],
          "price": "6155"
        },
        {
          "name": "CD-20 PERSENT",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "GAMMA GLUTAMYL TRANSFERASE , SERUM",
          "testList": [],
          "price": "345"
        },
        {
          "name": "TNF-ALPHA (SERUM)",
          "testList": [],
          "price": "4925"
        },
        {
          "name": "ANTISTREPTOLYSIN O ,  SERUM",
          "testList": [],
          "price": "645"
        },
        {
          "name": "PARVOVIRUS B19 DNA PCR",
          "testList": [],
          "price": "3050"
        },
        {
          "name": "RAPID FIARIA ANTIGEN DETECTION",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "HERPES SIMPLEX VIRUS-2 IgG serum",
          "testList": [],
          "price": "750"
        },
        {
          "name": "HISTOPATHOLOGY REVIEW BY DR ANJALI AMRAPURKAR",
          "testList": [],
          "price": "3200"
        },
        {
          "name": "HbsAG CONFIRMATION , SERUM",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "HERPES SIMPLEX VIRUS 1&2 IgG , ABS",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "COMPLETE PSA",
          "testList": [],
          "price": "1340"
        },
        {
          "name": "CREATININE KINASE-MUSCLE BRAIN(CK-MB)",
          "testList": [],
          "price": "910"
        },
        {
          "name": "ARSENIC BLOOD",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "BENCE-JONES PROTEIN",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "AFB SUSCEPTIBILITY- MGIT(13 DRUGS)",
          "testList": [],
          "price": "17120"
        },
        {
          "name": "ANTI CENTROMERE ANTIBODIES , SERUM",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "ANTIGLIADIN IgA ANTIBODIES , SERUM",
          "testList": [],
          "price": "2165"
        },
        {
          "name": "PARANEOPLASTIC DISORDER PROFILE",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "TORCH IgG EVALUATION , SERUM",
          "testList": [],
          "price": "1770"
        },
        {
          "name": "PANCREATIC ELASTASE , STOOL",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "PIVKA 2",
          "testList": [],
          "price": "3425"
        },
        {
          "name": "HERPES SIMPLEX VIRUS 1 IgM , SERUM",
          "testList": [],
          "price": "775"
        },
        {
          "name": "MYOSITIS PROFILE , SERUM",
          "testList": [],
          "price": "9630"
        },
        {
          "name": "NBS, ACYLCARNITINE & AMINO ACIDS",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "HIV DNA DETECTOR",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "DENGUE VIRUS ANTIBODIES , SERUM",
          "testList": [],
          "price": "1980"
        },
        {
          "name": "ENDOMYSIAL IgA  ANTIBODIES (IFA)",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "CD4 (LYMPHOCYTE ENUMERATION)",
          "testList": [],
          "price": "1820"
        },
        {
          "name": "CATECHOLAMINES , PLASMA",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "CHLAMYDIA TRACHOMATIS IgG , SERUM",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "ADH-ANTI DIURETIC HORMONE(VASOPRESSIN) , PLASMA",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "ALCHOHAL(ETHANOL) , SERUM",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "ALUMINIUM , PLASMA",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "VALPROIC ACID , SERUM",
          "testList": [],
          "price": "1125"
        },
        {
          "name": "ASCA IgA , SERUM",
          "testList": [],
          "price": "2305"
        },
        {
          "name": "ASCA IgG , SERUM",
          "testList": [],
          "price": "2305"
        },
        {
          "name": "ALLERGEN -RICE , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN -SOYABEAN , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALPHA-1-ANTITRYPSIN SERUM",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "AMINO ACID PROFILE",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "BRUCELLA TOTAL ANTIBODIES , SERUM",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "D3 HYDROXYBUTYRATE (KETONE BODY0 , SERUM",
          "testList": [],
          "price": "1180"
        },
        {
          "name": "IMMUNOFIXATION ELECTOPHORESIS , URINE",
          "testList": [],
          "price": "9630"
        },
        {
          "name": "LAP SCORE",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "MSI TEST(MLH1+MSH2+MSH6+PMS2)",
          "testList": [],
          "price": "6530"
        },
        {
          "name": "MICROFILARIA DETECTION , BLOOD",
          "testList": [],
          "price": "325"
        },
        {
          "name": "MYOGLOBIN , URINE(URINE SPOT, ECLIA)",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "NBS NEW PANEL",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "NBS PLUS-8P(WITH HB VARIENT ANALYSIS)",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "NEISSERIA GONORRHOEAE DNA PCR",
          "testList": [],
          "price": "3425"
        },
        {
          "name": "NEONATAL SCREENING , 170HP",
          "testList": [],
          "price": "1820"
        },
        {
          "name": "PRENET TOTAL(NIPS WITH MICRODELETION)",
          "testList": [],
          "price": "19260"
        },
        {
          "name": "THYROID SCREENING PANEL",
          "testList": [],
          "price": "1770"
        },
        {
          "name": "NORMETANEPHRINE(FREE PLASMA)",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "ANTI CARDIOLIPIN ANTIBODIES IgA , SERUM",
          "testList": [],
          "price": "1020"
        },
        {
          "name": "PV JAK2 REFLEX PANEL",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "PHENOBARBITOL",
          "testList": [],
          "price": "1125"
        },
        {
          "name": "METHAEMOGLOBIN ESTIMATION , BLOOD",
          "testList": [],
          "price": "1125"
        },
        {
          "name": "FREE PROTEIN S , PLASMA",
          "testList": [],
          "price": "5245"
        },
        {
          "name": "HPV TYPING BY SEQUENCING",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "HEPATITIS ACUTE EVALUATION -1 , SERUM",
          "testList": [],
          "price": "4015"
        },
        {
          "name": "HERPES SIMPLEX VIRUS-1 IgG , SERUM",
          "testList": [],
          "price": "750"
        },
        {
          "name": "CHALMYDIA TRACHOMATIS IgM , SERUM",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "DENGUE DUO ANTIGEN AND ANTIBODY TEST",
          "testList": [],
          "price": "1800"
        },
        {
          "name": "DIRECT LDL CHOLESTEROL , SERUM",
          "testList": [],
          "price": "400"
        },
        {
          "name": "ANTIGLIADIN IgG ANTIBODIES , SERUM",
          "testList": [],
          "price": "2165"
        },
        {
          "name": "BCR/ABL KINASE DOMAIN MUTATION ANALYSIS",
          "testList": [],
          "price": "10700"
        },
        {
          "name": "AML WITH NORMAL CYTOGENETICS",
          "testList": [],
          "price": "9095"
        },
        {
          "name": "ABS TO EXTRACTABLE NUCLEAR AG:SCL-70 , SERUM",
          "testList": [],
          "price": "1930"
        },
        {
          "name": "ALLERGY PANEL 2(29 ALLERGENS)",
          "testList": [],
          "price": "15090"
        },
        {
          "name": "PD-L1(SP 142)",
          "testList": [],
          "price": "9630"
        },
        {
          "name": "RBC FOLATE , BLOOD",
          "testList": [],
          "price": "2410"
        },
        {
          "name": "ALPHA THALASSEMIA MUTATION DETECTION",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "TYROXINE BINDING GLOBULIN (TBG) , SERUM",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "NEWBORN SCREENING FOR ACYLCARNITINE",
          "testList": [],
          "price": "5245"
        },
        {
          "name": "PANFUNGAL DNA DETECTOR",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "PARVOVIRUS B19 IgM",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "JAK2 EXON 12 MUTATION",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "JAPANESE ENCEPHALITIS VIRUS-RT-PCR",
          "testList": [],
          "price": "2945"
        },
        {
          "name": "KINSHIP ANALYSIS",
          "testList": [],
          "price": "26750"
        },
        {
          "name": "LIVER SCREEN",
          "testList": [],
          "price": "560"
        },
        {
          "name": "LUNG CANCER PROFILER NEXT",
          "testList": [],
          "price": "32100"
        },
        {
          "name": "MTHFR GENE MUTATION",
          "testList": [],
          "price": "4500"
        },
        {
          "name": "MEASLES IgG antibodies , SERUM",
          "testList": [],
          "price": "1875"
        },
        {
          "name": "FLOW CROSSMATCH",
          "testList": [],
          "price": "8670"
        },
        {
          "name": "HUMAN EPIDIDYMIS PROTEIN -4(HE4) , SERUM",
          "testList": [],
          "price": "2700"
        },
        {
          "name": "CLINICAL EXOME , ANALYSIS",
          "testList": [],
          "price": "23540"
        },
        {
          "name": "DUCHENNE/ BECKER MUSCULAR DYSTROPHY",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "ANTI SOLUBLE LIVER ANTIGEN , SERUM",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "AFB SUSCEPTIBILITY- MGIT 960(10 DRUGS)",
          "testList": [],
          "price": "13485"
        },
        {
          "name": "ABS TO EXTRACTABLE NUCLEAR AG:SM , SERUM",
          "testList": [],
          "price": "1950"
        },
        {
          "name": "ALLERGEN-DUST PANEL",
          "testList": [],
          "price": "3425"
        },
        {
          "name": "TB SECOND LINE DRUG RESISTANCE",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "XPERT MTB/RIF-EXTRAPULMONARY",
          "testList": [],
          "price": "2416"
        },
        {
          "name": "SOLUBLE TRANSFERRIN RECPTOR(STFR)",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "SYPHILIS ANTIBODIES",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "TOXOPLASMA IgG SERUM",
          "testList": [],
          "price": "750"
        },
        {
          "name": "MYCOBACTERIUM SPECIATION",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "MYOGLOBIN , SERUM",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "ONCOBRCA-NEXT",
          "testList": [],
          "price": "21400"
        },
        {
          "name": "L. DONOVONI BODIES DETECTION",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "FUNGAL SUSCEPTIBILITY",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "HEMOPHILIA PANEL",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "HTTG ANTIBODY",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "IGF-BP3 , SERUM",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "COBALT , BLOOD",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "ESTRIOL,UNCONJUGATED , SERUM",
          "testList": [],
          "price": "1180"
        },
        {
          "name": "BRAF V600E MUTATION",
          "testList": [],
          "price": "6315"
        },
        {
          "name": "CADMIUM , BLOOD",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSCEPTIBILITY-MGIT-960(5 DRUGS)",
          "testList": [],
          "price": "7600"
        },
        {
          "name": "ANTI MULLERIAN HORMONE",
          "testList": [],
          "price": "2300"
        },
        {
          "name": "VDRL , CSF",
          "testList": [],
          "price": "375"
        },
        {
          "name": "ANTI DNASE B , SERUM",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "VIRAL PNEUMONIA PANEL 1",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "REDUCING SUBSTANCES , STOOL",
          "testList": [],
          "price": "325"
        },
        {
          "name": "TOXOPLASMA IgG AVIDITY , SERUM",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "NBS G'6PD DEFICIENCY",
          "testList": [],
          "price": "750"
        },
        {
          "name": "NBS TOTAL GALACTOSE",
          "testList": [],
          "price": "485"
        },
        {
          "name": "PD-L1-22C3-DAKO",
          "testList": [],
          "price": "10810"
        },
        {
          "name": "PML RARA T(15:17)",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "PARAVOVIRUS B19 IgG",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "PORPHOBILINOGEN , URINE",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "IGG , CSF",
          "testList": [],
          "price": "1930"
        },
        {
          "name": "IMMUNOFLUORESCENCE ASSAY , IgG , IgA , IgM , C3",
          "testList": [],
          "price": "4335"
        },
        {
          "name": "INHIBIN A , SERUM",
          "testList": [],
          "price": "1715"
        },
        {
          "name": "KAPPA FREE LIGHT CHAIN",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "KINSHIP ADDON",
          "testList": [],
          "price": "8025"
        },
        {
          "name": "LIPID PROFILE-NON FASTING , SERUM",
          "testList": [],
          "price": "590"
        },
        {
          "name": "MERCURY , BLOOD",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "FACTOR 5 ACTIVITY(CITRATED PLASMA)",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "FACTOR 13 ACTIVITY , PLASMA",
          "testList": [],
          "price": "3105"
        },
        {
          "name": "FANCONIS ANEMIA",
          "testList": [],
          "price": "8670"
        },
        {
          "name": "HDL CHOLESTEROL , SERUM",
          "testList": [],
          "price": "270"
        },
        {
          "name": "HLA A , B , C , DR , DQ , DP , LOCI",
          "testList": [],
          "price": "34240"
        },
        {
          "name": "HLA A , B , DR LOCI DUO(PATIENT+DONAR)",
          "testList": [],
          "price": "37450"
        },
        {
          "name": "HELICOBACTOR PYLORI IgA",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "CHLAMYDIA TRACHOMATIS IgA",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "CHROMIUM , BLOOD",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "DENGUE DUO ANTIGEN & ANTIBODY TESTS",
          "testList": [],
          "price": "3320"
        },
        {
          "name": "DIABETES PROFILE",
          "testList": [],
          "price": "1660"
        },
        {
          "name": "DOPAMINE(PLASMA, EIA)",
          "testList": [],
          "price": "3535"
        },
        {
          "name": "ER/PGR , IHC(ESTROGEN RECEPTOR)",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "ENDOMYSIAL IgA ANTIBODIES",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "EXTENDED ECZEMA PANEL",
          "testList": [],
          "price": "11235"
        },
        {
          "name": "ANTI MPO AUTOANTIBODIES , SERUM",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "ARTHRITIS PANEL",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "5-HYDROXY INDOLE ACETIC ACID",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "ABS  TO EXTRACTABLE NUCLEAR AG:SS-A , SERUM",
          "testList": [],
          "price": "1950"
        },
        {
          "name": "ABS  TO EXTRACTABLE NUCLEAR AG:SS-B , SERUM",
          "testList": [],
          "price": "1950"
        },
        {
          "name": "ACTIVATED PROTEIN C RESISTANCE",
          "testList": [],
          "price": "4305"
        },
        {
          "name": "ALCHOHAL(ETHANOL) , URINE",
          "testList": [],
          "price": "2625"
        },
        {
          "name": "ALLERGY SCREENING PANEL-1",
          "testList": [],
          "price": "9630"
        },
        {
          "name": "VANCOMYCIN , SERUM",
          "testList": [],
          "price": "6530"
        },
        {
          "name": "VZV PCR",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "ABS  TO EXTRACTABLE NUCLEAR AG U1SNRP , SERUM",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "PRENATAL AMNIOTIC FLUID FISH",
          "testList": [],
          "price": "8560"
        },
        {
          "name": "PSEUDOCHOLINESTERASE , SERUM",
          "testList": [],
          "price": "1340"
        },
        {
          "name": "PYRUVATE , SERUM",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "RUBELLA IgM , SERUM",
          "testList": [],
          "price": "750"
        },
        {
          "name": "SIROLIMUS , EDTA/HEPARIN WHOLE BLOOD",
          "testList": [],
          "price": "6210"
        },
        {
          "name": "SPERM ANTIBODIES , SERUM",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "SPINAL MUSCULAR ATROPHY MICRODELETION PCR",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "TPC PLUS",
          "testList": [],
          "price": "590"
        },
        {
          "name": "THYPROBE , SERUM",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "TTG ADVANCED(NEOEPITOPES)",
          "testList": [],
          "price": "2000"
        },
        {
          "name": "MYCOPLASMA PNEUMONIAE IgM ANTIBODIES",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "NICOTINE METABOLITE , SERUM",
          "testList": [],
          "price": "1930"
        },
        {
          "name": "NOR-ADRENALIN PLASMA",
          "testList": [],
          "price": "3535"
        },
        {
          "name": "OSTEOCALCIN , SERUM",
          "testList": [],
          "price": "3320"
        },
        {
          "name": "PIVKA TOTAL",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "KAPPA LIGHT CHAIN QUANTITATIVE",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "KIDNEY BIOPSY TX",
          "testList": [],
          "price": "5140"
        },
        {
          "name": "LAMBDA LIGHT CHAIN QUANTITATIVE",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "MDM2",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "FACTOR 9 ACTIVITY PLASMA",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "FERTILITY PANEL , MALE , SERUM",
          "testList": [],
          "price": "2600"
        },
        {
          "name": "FERTILITY PANEL , SERUM",
          "testList": [],
          "price": "2800"
        },
        {
          "name": "G6PD QUALITATIVE REFLEX QUANTITATIVE",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "HEPATITIS B VIRUS DNA DETECTOR",
          "testList": [],
          "price": "4495"
        },
        {
          "name": "DENGUE RNA PCR",
          "testList": [],
          "price": "3770"
        },
        {
          "name": "ER/PR & C ERB B2/HER 2 NEU",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "EPSTEIN-BARR VIRUS(EBV) DNA PCR",
          "testList": [],
          "price": "4550"
        },
        {
          "name": "EVEROLIMUS , EDTA/HEPARIN WHOLE BLOOD",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "B2 MICROGLOBULIN , URINE",
          "testList": [],
          "price": "1770"
        },
        {
          "name": "BILIRUBIN , DIRECT , SERUM",
          "testList": [],
          "price": "240"
        },
        {
          "name": "BRUCELLA IgG ANTIBODIES , SERUM",
          "testList": [],
          "price": "1310"
        },
        {
          "name": "VITAMIN B2 , SERUM/EDTA PLASMA",
          "testList": [],
          "price": "3640"
        },
        {
          "name": "ALLERGEN -GLUTEN , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGY PANEL 3(19 ALLERGENS)",
          "testList": [],
          "price": "11235"
        },
        {
          "name": "ANEMIA PROFILE 2",
          "testList": [],
          "price": "1555"
        },
        {
          "name": "WEST NILE VIRUS IgM",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "POTASSIUM , URINE",
          "testList": [],
          "price": "270"
        },
        {
          "name": "ROTA VIRUS ANTIGEN DETECTION",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "SINGLE ANTIGEN BEAD CLASS 1 (ABCLOCI)",
          "testList": [],
          "price": "24610"
        },
        {
          "name": "SINGLE ANTIGEN BEAD CLASS 2",
          "testList": [],
          "price": "21400"
        },
        {
          "name": "SOLID TUMOR RNA PROFILE NEXT",
          "testList": [],
          "price": "22470"
        },
        {
          "name": "SPECIAL STAINS PANEL",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "TESTICULAR CA MONITOR , SERUM",
          "testList": [],
          "price": "1715"
        },
        {
          "name": "TOTAL P1NP , SERUM",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "MYCOBACTERIA DEYECTION A-R SMEAR",
          "testList": [],
          "price": "645"
        },
        {
          "name": "OSTEOSCREEN PANEL 2",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "PML RA RA T(15:17) , QUALITATIVE",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "PLASMINOGEN  ACTIVATOR INHIBITOR -1",
          "testList": [],
          "price": "12840"
        },
        {
          "name": "IGVH GENE MUTATION",
          "testList": [],
          "price": "9310"
        },
        {
          "name": "LAMBDA FREE LIGHT CHAIN",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "LEUKEMIA TRANSLOCATION PANEL -1",
          "testList": [],
          "price": "8025"
        },
        {
          "name": "LYME DISEASE(BORRELIA BURGDORFERI IgM)",
          "testList": [],
          "price": "1980"
        },
        {
          "name": "LUMPHOCYTE ENUMERATION T &B CELLS",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "MIC TESTING FOR RAPIDLY GROWING MICROBACTERIA",
          "testList": [],
          "price": "10700"
        },
        {
          "name": "MSI ANALYSIS",
          "testList": [],
          "price": "10700"
        },
        {
          "name": "MEASLES IgM antibodies , SERUM",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "METHYLGUANINE METHYLTRANSFERESE(MGMT)",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "MULTIPLE MYELOMA ADVANCE FISH PANEL",
          "testList": [],
          "price": "19260"
        },
        {
          "name": "MUMPS IgG ANTIBODIES , SERUM",
          "testList": [],
          "price": "1930"
        },
        {
          "name": "FACTOR 2 (PROTHROMBIN) MUTATION",
          "testList": [],
          "price": "8560"
        },
        {
          "name": "FRAGILE X (FMR1) MUTATION SCREEN",
          "testList": [],
          "price": "7815"
        },
        {
          "name": "HVB DRUG RESISTANCE",
          "testList": [],
          "price": "8560"
        },
        {
          "name": "HLA B LOCUS",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "HEP A & B EVALUATION",
          "testList": [],
          "price": "7705"
        },
        {
          "name": "HUNTINGTON DISEASE MOLECULAR ANALYSIS",
          "testList": [],
          "price": "6210"
        },
        {
          "name": "COMPREHENSIVE RHINITIS ALLERGY PANEL",
          "testList": [],
          "price": "6155"
        },
        {
          "name": "CULTURE,NASOPHARYNGEAL , ISOLATION PANEL",
          "testList": [],
          "price": "1100"
        },
        {
          "name": "DOWNS SYNDROME/AMBIGUOUS EXTERNAL GENITALIA",
          "testList": [],
          "price": "4495"
        },
        {
          "name": "ECZEMA /GIT(VEG) PANEL",
          "testList": [],
          "price": "6155"
        },
        {
          "name": "EXTENDED RHINITIS/ASTHMA PANEL - ADULT",
          "testList": [],
          "price": "11235"
        },
        {
          "name": "FIP1L1-PDGFRA GENE REARRANGEMENT",
          "testList": [],
          "price": "6530"
        },
        {
          "name": "FLT3 MUTATION DETECTION",
          "testList": [],
          "price": "5245"
        },
        {
          "name": "ANTI PR3 AUTOANTIBODIES , SERUM",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "BACTERIAL ANTIGEN DETECTION",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "BETA-SCREEN",
          "testList": [],
          "price": "375"
        },
        {
          "name": "CD-25 PERCENT",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "CMV VIRAL LOAD/GCV RESISTANCE COMBI TEST",
          "testList": [],
          "price": "10700"
        },
        {
          "name": "CADMIUM /RANDOM URINE",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "AFB SUSC. PYRAZINAMIDE",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSC. SIRE PANEL",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "ADRENALIN , PLASMA",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "ALBUMIN+GLOBULIN+A/G RATIO , SERUM",
          "testList": [],
          "price": "325"
        },
        {
          "name": "VWF ANTIGEN , PLASMA",
          "testList": [],
          "price": "11235"
        },
        {
          "name": "VIRAL PNEUMONIA PANEL LI",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "RETT SYNDROME DNA SEQUENCING",
          "testList": [],
          "price": "5620"
        },
        {
          "name": "SS18(SYT) GENE REARRANGEMENT FISH",
          "testList": [],
          "price": "4230"
        },
        {
          "name": "SEPSISCREEN",
          "testList": [],
          "price": "19260"
        },
        {
          "name": "SOLID TUMOR DNA PROFILER NEXT",
          "testList": [],
          "price": "22470"
        },
        {
          "name": "TLE-1",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "TISSU FOR PROCESSING",
          "testList": [],
          "price": "375"
        },
        {
          "name": "TRANSMISSION ELECTRON MICROSCOPY",
          "testList": [],
          "price": "10700"
        },
        {
          "name": "TRUE CARD PANEL",
          "testList": [],
          "price": "1200"
        },
        {
          "name": "URINE GLYCOSAMINOGLYCANS",
          "testList": [],
          "price": "2570"
        },
        {
          "name": "NATURAL KILLAR PANEL",
          "testList": [],
          "price": "5030"
        },
        {
          "name": "P 16",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "P16",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "PANCREATIC CANCER , MONITORING",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "PAPAYA",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "PARACETAMOL",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "PHILADEELPHIA CHROMOSOME QUANTIFICATION",
          "testList": [],
          "price": "7225"
        },
        {
          "name": "PNEUMOCYSTIS CARINII DETECTION",
          "testList": [],
          "price": "3105"
        },
        {
          "name": "LEGINELLA  PNEUMOPHILA ANTIGEN DETECTION",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "LEPTIN",
          "testList": [],
          "price": "5460"
        },
        {
          "name": "LEUKEMIA TRANSLOCATION PANEL -2",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "LEUKEMIA TRANSLOCATION PANEL -6",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "LEVETIRACETAM",
          "testList": [],
          "price": "5675"
        },
        {
          "name": "LYME DISEASE(BORRELIA BURGDORFERI IgG)",
          "testList": [],
          "price": "1980"
        },
        {
          "name": "LYMPHOMA AGGRESIVE PANEL",
          "testList": [],
          "price": "12840"
        },
        {
          "name": "MYCO3PLEX",
          "testList": [],
          "price": "3480"
        },
        {
          "name": "HCV ALPHA",
          "testList": [],
          "price": "17120"
        },
        {
          "name": "HLA A LOCUS",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "HLA DQ LOCUS",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "HEP B VIRUS EVALUATION",
          "testList": [],
          "price": "4550"
        },
        {
          "name": "HEP SCREEN",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "HEPATITIS DELTA IgG ANTIBODIES , SERUM",
          "testList": [],
          "price": "2035"
        },
        {
          "name": "IA2-INSULIN(SERUM , EIA)",
          "testList": [],
          "price": "3105"
        },
        {
          "name": "IDH1 AND IDH 2 GENE MUTATION",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "CHLAMYDIA PNEUMONIAE IgA ANTIBODIES",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "COLORECTAL CANCER PROFILER NEXT",
          "testList": [],
          "price": "28195"
        },
        {
          "name": "EBV VIRAL LOAD REAL TIME PCR",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "EPSTEIN-BARR VIRUS EARLY ANTIGEN IgG",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "FISH FOR 17P(TP53) ABNORMALITIES",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "ANTI THROMBIN 3 ANTIGEN , PLASMA",
          "testList": [],
          "price": "4925"
        },
        {
          "name": "BACTERIAL MENINGITIS MULTIPLEX PCR",
          "testList": [],
          "price": "4550"
        },
        {
          "name": "BACTERIAL PNEUMONIA PANEL",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "BETA HCG",
          "testList": [],
          "price": "850"
        },
        {
          "name": "BRAST MONITOR 1 , SERUM",
          "testList": [],
          "price": "2465"
        },
        {
          "name": "CA 125 (OVARIAN CANCER MONITOR) , SERUM",
          "testList": [],
          "price": "1450"
        },
        {
          "name": "CD3/CD-19 PERSENT",
          "testList": [],
          "price": "6210"
        },
        {
          "name": "CH50 COMPLEMENT TOTAL SERUM",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "CSF, MEASELS (RUBEOLA) , IgG ANTIBODIES",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "5- AMINOLEVULINIC ACID (RANDOM) , URINE",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "AFB LJ SUSC -CYCLOSERINE",
          "testList": [],
          "price": "1125"
        },
        {
          "name": "ALK(EML4-ALK) FISH",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "APA(PHOSPHOLIPID)-IgA (SERUM , EIA)",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ACID FAST BACILLI CULTURE",
          "testList": [],
          "price": "1650"
        },
        {
          "name": "ALLERGEN-SHRIMP , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN -PHADIATOP INFANT , SERUM",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "AMINO ACIDS , PLASMA",
          "testList": [],
          "price": "8025"
        },
        {
          "name": "ANEMIA SCREEN",
          "testList": [],
          "price": "750"
        },
        {
          "name": "ACUTE HEPATITIS VIRUS EVALUATION -2",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "ALLERGEN-DERMATOPHAGOIDES FARINAE",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN -EGG WHITE , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN -EGG YOLK , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN -FOOD PANEL 7",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "ALLERGEN -LEMON , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-MUSHROOM , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-ORANGE , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-PEANUT , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN STRAWBERRY , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "AMINO ACIDS,24 HOURS URINE",
          "testList": [],
          "price": "8830"
        },
        {
          "name": "ANDROGEN RECEPTOR STUDIES",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "GATA 3",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "GIST PDGFRA GENE MUTATIONS",
          "testList": [],
          "price": "10700"
        },
        {
          "name": "HAM'S(ACIDIFIED SERUM) TEST , BLOOD",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "HDV RT-PCR",
          "testList": [],
          "price": "5620"
        },
        {
          "name": "HFE GENE MUTATIONS",
          "testList": [],
          "price": "8025"
        },
        {
          "name": "HLA - A B C DR DQ FOR HSCT",
          "testList": [],
          "price": "28890"
        },
        {
          "name": "HLA C LOCUS",
          "testList": [],
          "price": "8560"
        },
        {
          "name": "HLA DP AND DQ LOCUS",
          "testList": [],
          "price": "11235"
        },
        {
          "name": "HAPATITIS A VIRUS IgM ANTIBODIES , SERUM",
          "testList": [],
          "price": "1200"
        },
        {
          "name": "HERDITARY CACER PANEL-NEXT",
          "testList": [],
          "price": "36380"
        },
        {
          "name": "HISTAMINE",
          "testList": [],
          "price": "7010"
        },
        {
          "name": "CELIAC TISSU BIOPSY",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "CHICK PEA (CICER ARIENTINUS) , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "CHLAMYDIA PNEUMONIAE IgG ANTIBODIES",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "CHLAMYDIA TRACHOMATIS ANTIBODIES , SERUM",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "COPPER , RANDOM URINE",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "CUSTOM LEUKEMIA PANEL",
          "testList": [],
          "price": "6500"
        },
        {
          "name": "CYCLOSPORINE EDTA/HEPARIN WHOLE BLOOD",
          "testList": [],
          "price": "3265"
        },
        {
          "name": "CYSTIS FIBROSIS DELTA F508 GENE MUTATION",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "DIABETES MONITOR",
          "testList": [],
          "price": "1875"
        },
        {
          "name": "DIPTHERIA IgG ABS , SERUM",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "DUCHENNE MUSCULAR DYSTROPHY",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "E.HISTOLYTICA ANTIGEN DETECTION",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "ER , PGR , C ERB B2 &KL-67",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "ECZEMA /GIT(NON-VEG &VEG) PANEL",
          "testList": [],
          "price": "10165"
        },
        {
          "name": "EXTENDED RHINITIS/ASTHMA PANEL - CHILD",
          "testList": [],
          "price": "11235"
        },
        {
          "name": "FISH FOR 5Q DELETION",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "ANTI HISTONES ANTIBODIES",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "ANTIPARIETAL CELL ANTIBODIES WITH TITERS",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "ARGINASE-1",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "B-CAROTENE , SERUM",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "BETA CROSSLAPS(BETA CTX) , SERUM",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "BETA THALASSEMIA MUTATION DETECTION",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "BIOTINIDASE , SERUM",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "BORDETELLA PERTUSSIS IgG(SERUM , EIA)",
          "testList": [],
          "price": "2785"
        },
        {
          "name": "BRAIN BIOPSY WITH CUSTOM IHC",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "C-KIT GIST",
          "testList": [],
          "price": "11770"
        },
        {
          "name": "C-MYC",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "C-KIT MUTATION",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "CD4 IMMUNOFLUORESCENCE",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "CALR GENE MUTATIONS",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "CD3/CD16+56- PERSENT",
          "testList": [],
          "price": "6210"
        },
        {
          "name": "CD3/CD4-ABSOLUTE",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "CEBPA MUTATION DETECTION",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "ACTH STIMULATION TEST , 1-HOUR , SERUM",
          "testList": [],
          "price": "2785"
        },
        {
          "name": "AFB SMEAR-5 SAMPLES",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "AFB SUSC PAS 1.0 MG/ML",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSC. SIREP PANEL",
          "testList": [],
          "price": "7600"
        },
        {
          "name": "ATRX(D5)",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "ACETONE , URINE",
          "testList": [],
          "price": "860"
        },
        {
          "name": "WEST NILE VIRUS IgG",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "WHITE CELL COUNT AND DIFFERENCIA",
          "testList": [],
          "price": "225"
        },
        {
          "name": "XPERT/MTB/RIF ULTRA-PULMONARY",
          "testList": [],
          "price": "2416"
        },
        {
          "name": "PREALBUMIN , SERUM",
          "testList": [],
          "price": "3535"
        },
        {
          "name": "PROTEIN ELECTROPHORESIS",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "ROS1 GENE REARRANGEMENT BY FISH",
          "testList": [],
          "price": "10165"
        },
        {
          "name": "REDUCING SUBSTANCES INURINE RANDOM",
          "testList": [],
          "price": "325"
        },
        {
          "name": "SERUM LIPOPROTEIN ELECTROPHORESIS",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "SPINOCEREBELLAR ATAXIA  TYPE 12",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "SUCROSE LYSIS TEST , BLOOD",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "TB CULTURE POSITIVE REFLEX MDR",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "THEOPHYLLINE , SERUM",
          "testList": [],
          "price": "1180"
        },
        {
          "name": "THROMBINE TIME (T.T.) , PLASMA",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "TOTAL GALACTOSE(TGAL)>1 MONTH",
          "testList": [],
          "price": "570"
        },
        {
          "name": "TRYPANOSOMA CRUZI IgG",
          "testList": [],
          "price": "2410"
        },
        {
          "name": "MYCO3PLEX AD WITH FREE ADA",
          "testList": [],
          "price": "3375"
        },
        {
          "name": "MYELOPEROXIDASE (MPO) STAIN",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "NBS PHENYLKETONURIA(PHENYLALANINE)",
          "testList": [],
          "price": "485"
        },
        {
          "name": "NPM 1 MUTATION DETECTION",
          "testList": [],
          "price": "3960"
        },
        {
          "name": "NATURAL KILLAR CELL EVALUATION",
          "testList": [],
          "price": "4495"
        },
        {
          "name": "NEISSERIA GONORRHOEAE  CULTURE",
          "testList": [],
          "price": "1350"
        },
        {
          "name": "NEONATAL SCREENING , IRT",
          "testList": [],
          "price": "485"
        },
        {
          "name": "NEONATAL SCREENING , MSUD",
          "testList": [],
          "price": "485"
        },
        {
          "name": "NON VEG PANEL",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "OSTEOMON",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "OSTEOSCREEN PANEL 1",
          "testList": [],
          "price": "3425"
        },
        {
          "name": "PGS:EMBRYO-10",
          "testList": [],
          "price": "96300"
        },
        {
          "name": "PGS:EMBRYO-3",
          "testList": [],
          "price": "44940"
        },
        {
          "name": "PGS:EMBRYO-9",
          "testList": [],
          "price": "96300"
        },
        {
          "name": "PIK3CA GENE MUTATION",
          "testList": [],
          "price": "6100"
        },
        {
          "name": "PEADIATRIC ALLERGY PANEL",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "PORPHOBILINOGEN , RANDOM URINE",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "PORPHYRINS , 24HRS URINE",
          "testList": [],
          "price": "7065"
        },
        {
          "name": "IgG HEAVY CHAIN(SURFACE)",
          "testList": [],
          "price": "2945"
        },
        {
          "name": "INDOOR PANEL",
          "testList": [],
          "price": "6155"
        },
        {
          "name": "JC VIRUS DNA  QUANTITATIVE",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "KRAS MUTATION DETECTION",
          "testList": [],
          "price": "6315"
        },
        {
          "name": "LAMOTRIGINE",
          "testList": [],
          "price": "6260"
        },
        {
          "name": "LEAD , RANDOM URINE",
          "testList": [],
          "price": "2305"
        },
        {
          "name": "LEPTOSPIRA DNA PCR",
          "testList": [],
          "price": "2380"
        },
        {
          "name": "LIPID SCREEN , SERUM",
          "testList": [],
          "price": "590"
        },
        {
          "name": "LIQUID BIOPSY-EGFR TRUE",
          "testList": [],
          "price": "16050"
        },
        {
          "name": "MLH1 HYPERMETHYLATION",
          "testList": [],
          "price": "7600"
        },
        {
          "name": "MTD-88",
          "testList": [],
          "price": "16050"
        },
        {
          "name": "MERCURY , 24HRS URINE",
          "testList": [],
          "price": "3640"
        },
        {
          "name": "METHYLMALONIC ACID , URINE",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "MINIMAL RESIDUAL DISEASE (MRD)",
          "testList": [],
          "price": "14445"
        },
        {
          "name": "MUMPS IgM ANTIBODIES , SERUM",
          "testList": [],
          "price": "17770"
        },
        {
          "name": "FACTOR 13 QUANTITATIVE ASSAY",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "FOOD PANEL-VEG 2",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "FRIEDREICH'S ATAXIA",
          "testList": [],
          "price": "7065"
        },
        {
          "name": "G6PD GENE MUTATIONS",
          "testList": [],
          "price": "4400"
        },
        {
          "name": "SALICYLATE",
          "testList": [],
          "price": "1445"
        },
        {
          "name": "SELENIUM , RANDOM URINE",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "SEMEN FRUCTOSE",
          "testList": [],
          "price": "325"
        },
        {
          "name": "SEROTONIN",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "SEROTONIN , 5-HYDROXY TRYPTAMINE URINE",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "SICKLE CELL DNA PCR PND",
          "testList": [],
          "price": "9630"
        },
        {
          "name": "SICKLE CELL PCR",
          "testList": [],
          "price": "3425"
        },
        {
          "name": "SICKLE CELL VARIENT ANALYSIS",
          "testList": [],
          "price": "535"
        },
        {
          "name": "SINGLE ANTIGEN BEAD CLASS 1(A B C LOCI)",
          "testList": [],
          "price": "24610"
        },
        {
          "name": "SPINAL CEREBRAL ATAXIA TYPE-1",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "SPINAL CEREBRAL ATAXIA TYPE-2",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "SPINAL CEREBRAL ATAXIA TYPE-3",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "SPINAL CEREBRAL ATAXIA TYPE-6",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "SPINAL CEREBRAL ATAXIA TYPE-7",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "SPINAL MUSCULAR ATROPHY MICRODELETION PCR PNDT",
          "testList": [],
          "price": "7705"
        },
        {
          "name": "SPINOCEREBELLAR ATAXIA TYPE 10",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "SPINOCEREBELLAR ATAXIA TYPE 17",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "SPUTUM EOSINOPHILS",
          "testList": [],
          "price": "165"
        },
        {
          "name": "SRY DELETION , FISH",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "STOOL FOR  OCCULT BLOOD",
          "testList": [],
          "price": "195"
        },
        {
          "name": "STOOL FOR PH AND REDUCING SUBSTANCE",
          "testList": [],
          "price": "120"
        },
        {
          "name": "STOOL-HANGING DROP",
          "testList": [],
          "price": "430"
        },
        {
          "name": "STREPTOCOCCUS GROUP B ANTLGEN , CSF",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "STREPTOCOCCUS  PNEUMONIAE ANTIGEN , CSF",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "SUDAN BLACK STAIN , BLOOD/SMEAR",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "TEL/AML",
          "testList": [],
          "price": "6100"
        },
        {
          "name": "TFE-3",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "TROPONIN T",
          "testList": [],
          "price": "1800"
        },
        {
          "name": "TETANUS IgG ANTIBODIES",
          "testList": [],
          "price": "4710"
        },
        {
          "name": "THALIUM , BLOOD",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "TORCH IgM EVALUATION , SERUM",
          "testList": [],
          "price": "1770"
        },
        {
          "name": "TOTAL PROTEIN , CSF",
          "testList": [],
          "price": "225"
        },
        {
          "name": "TOXORUBELLA PCR",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "TREE POLLENS PANEL",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "TUBERCULOSIS MONITORING PANEL",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "TUBERCULOSIS PANEL",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "TWO STEP STRATEGY FOR OGTT  IN GDM",
          "testList": [],
          "price": "85"
        },
        {
          "name": "URINE FOR BILE PIGMENTS",
          "testList": [],
          "price": "110"
        },
        {
          "name": "URINE FOR BILE PIGMENTS &SALTS",
          "testList": [],
          "price": "75"
        },
        {
          "name": "VEG PANEL",
          "testList": [],
          "price": "6155"
        },
        {
          "name": "VITAMIN D COMBO(VIT D , P , CA , ALKP)",
          "testList": [],
          "price": "2035"
        },
        {
          "name": "WARFARIN SENSITIVITY BY GENOTYPING",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "WEED POLLENS PANEL",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "WHOLE EXOME ANALYSIS-SOLO",
          "testList": [],
          "price": "26750"
        },
        {
          "name": "XPERT CARBA-R ASSAY",
          "testList": [],
          "price": "6155"
        },
        {
          "name": "ZAP-70",
          "testList": [],
          "price": "7815"
        },
        {
          "name": "ZINC , RANDOM URINE",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "NRAS EXON1 AND 2 MUTATION",
          "testList": [],
          "price": "6315"
        },
        {
          "name": "NTRK IHC",
          "testList": [],
          "price": "3855"
        },
        {
          "name": "NUT",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "NAPSIN A",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "NEONATAL FISH  13&21(2 PROBE)",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "NEONATAL SCREENING , G6PD",
          "testList": [],
          "price": "485"
        },
        {
          "name": "NERVE BIOPSY WITH NEUROFILAMENT",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "NEW NEONATAL SCREENING PANEL",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "NICKLE RANDOM URINE",
          "testList": [],
          "price": "3855"
        },
        {
          "name": "NICOTINE METABOLITE , URINE",
          "testList": [],
          "price": "1930"
        },
        {
          "name": "NOCARDIA SPECIATION",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "NOR-ADRENALIN , URINE",
          "testList": [],
          "price": "3855"
        },
        {
          "name": "ORAL GLUCOSE TOLERANCE TEST- GESTATIONA",
          "testList": [],
          "price": "325"
        },
        {
          "name": "OSMOLALITY , 24HRS URINE",
          "testList": [],
          "price": "1235"
        },
        {
          "name": "OSMOTIC FRAGILITY TEST , BLOOD",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "OUTDOOR PANEL",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "OVA AND PARASITE : COCCIDIA EVALUATION",
          "testList": [],
          "price": "805"
        },
        {
          "name": "P40",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "PAPP-A",
          "testList": [],
          "price": "1660"
        },
        {
          "name": "PDGFRB(TEL-PDGR BETA , T(5;12))",
          "testList": [],
          "price": "6210"
        },
        {
          "name": "PGS:EMBRYO-1",
          "testList": [],
          "price": "14980"
        },
        {
          "name": "PGS:EMBRYO-4",
          "testList": [],
          "price": "59920"
        },
        {
          "name": "PGS:EMBRYO-5",
          "testList": [],
          "price": "74900"
        },
        {
          "name": "PGS:EMBRYO-6",
          "testList": [],
          "price": "85600"
        },
        {
          "name": "PGS:EMBRYO-7",
          "testList": [],
          "price": "96300"
        },
        {
          "name": "PNH (CD55 AND CH59)",
          "testList": [],
          "price": "7385"
        },
        {
          "name": "PANFUNGAL",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "PHENYLKETONURIA (PKU)>1 MONTH",
          "testList": [],
          "price": "945"
        },
        {
          "name": "PHILADELPHIA CHROMOSOME",
          "testList": [],
          "price": "5245"
        },
        {
          "name": "PLASMA VERY LONG CHAIN FATTY ACIDS",
          "testList": [],
          "price": "22470"
        },
        {
          "name": "PORPHOBILINOGEN QUANTITATIVE",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "PORPHYRINS SPECIATION , 24 HRS URINE",
          "testList": [],
          "price": "7065"
        },
        {
          "name": "PRADER-WILLI SYNDROME-MS PCR",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "PRE-ECLA",
          "testList": [],
          "price": "6500"
        },
        {
          "name": "PRENATAL AMNIOTIC FLUID KARYOTYPING",
          "testList": [],
          "price": "13270"
        },
        {
          "name": "PRENATAL AMNIOTIC FLUID KARYOTYPING+FISH",
          "testList": [],
          "price": "18725"
        },
        {
          "name": "PREOPERATIVE PANEL",
          "testList": [],
          "price": "1770"
        },
        {
          "name": "PROTEIN C ANTIGEN (CITRATED PLASMA , ELFA)",
          "testList": [],
          "price": "4605"
        },
        {
          "name": "RBC COUNT , BLOOD",
          "testList": [],
          "price": "130"
        },
        {
          "name": "RET GENE MUTATIONS",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "RSV-RESPIRATORY SYNCYTIAL VIRUS IgM",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "RSV-RESPIRATORY SYNCYTIAL VIRUS IgG",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "RECURRENT MISCARRIAGE PANEL",
          "testList": [],
          "price": "18725"
        },
        {
          "name": "RED KIDNEY BEANS",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "RICKETTSIA PCR",
          "testList": [],
          "price": "3500"
        },
        {
          "name": "RUBELLA RNA PCR",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "SALL-4",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "SMA CARRIER DETECTION",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "HLA-A , B , C , DRB1 , DQ1 , HR-LOCI TYPING",
          "testList": [],
          "price": "17120"
        },
        {
          "name": "HLA-A , B , DRB1 , HR-LOCI TYPING",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "HLA-A B DR FOR HSCT",
          "testList": [],
          "price": "20865"
        },
        {
          "name": "HEAMOPHILUS INFLUENZAE B ANTIGEN , CSF",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "HAPTOGLOBIN GENOTYPING-PCR",
          "testList": [],
          "price": "3960"
        },
        {
          "name": "ABE ANTIGEN , ANTIBODY SCREEN",
          "testList": [],
          "price": "1800"
        },
        {
          "name": "HEMODIALYSIS WATER CULTURE",
          "testList": [],
          "price": "1000"
        },
        {
          "name": "HEPATITIS BE ANTIBODIES , SERUM",
          "testList": [],
          "price": "1000"
        },
        {
          "name": "HEPATITIS BE ANTIGEN , SERUM",
          "testList": [],
          "price": "1100"
        },
        {
          "name": "HOMOCYSTEINE REFLEX VIT B12 REFLEX FOLIC ACID",
          "testList": [],
          "price": "1930"
        },
        {
          "name": "HOMOGENTISIC ACID URINE",
          "testList": [],
          "price": "1070"
        },
        {
          "name": "HYPERTENSION PROFILE",
          "testList": [],
          "price": "1820"
        },
        {
          "name": "IGH/CCND1 MANTLE CELL  LYMPHOMA",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "IgD HEAVY CHAIN(SURFACE)",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "IgG4",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "IMMUNOFIXATION ELECTROPHORESIS,CSF",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "IMMUNOFLUORESCENCE ASSAY",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "IMMUNOFLUORESCENCE ASSAY , C3(TISSU)",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "IMMUNOFLUORESCENCE ASSAY , IgA(TISSU)",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "IMMUNOFLUORESCENCE ASSAY , IgG(TISSU)",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "INVERSION 16 (FISH)",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "KRAS CODON 61 MUTATION DETECTION",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "L-LACTATE , CSF",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "L-LACTATE , FLUORIDE PLASMA",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "LP-PLA2(PLAC)-CARDIAC RISK MARKER",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "LEAD , 24HRS URINE",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "LEPRA SMEAR",
          "testList": [],
          "price": "300"
        },
        {
          "name": "LEUKEMIA TRANSLOCATION PANEL",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "LIQUIPREP CYTOLOGY",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "LYMPHOCYTE SUBSET ENUMERATION",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "MLL 11Q REARRANGEMENT",
          "testList": [],
          "price": "4605"
        },
        {
          "name": "MPL MUTATION DETECTION",
          "testList": [],
          "price": "7280"
        },
        {
          "name": "MUM-1",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "MYCN(N-MYC) GENE AMPLIFICATION",
          "testList": [],
          "price": "12840"
        },
        {
          "name": "MANGANESE , BLOOD",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "MANGANESE , RANDOM URINE",
          "testList": [],
          "price": "3535"
        },
        {
          "name": "MATERNAL SCREEN",
          "testList": [],
          "price": "7170"
        },
        {
          "name": "MENOPAUSAL DIAGNOSTIC PANEL",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "MERCURY , RANDOM URINE",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "MESQUITE(PROSOPIS JULIFLORA) , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "METHDONE , URINE",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "MYCOBAT CULTURE-BACTEC",
          "testList": [],
          "price": "2000"
        },
        {
          "name": "MTYCOBACTERIUM SPECIATION -COMMON",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "MYCOPLASMA PNEUMONIAE IgG ANTIBODIES",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "MYTONIC DYSTROPHY TYPE 1(DM1)",
          "testList": [],
          "price": "8560"
        },
        {
          "name": "NBS BIOTIDANSE",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "NBS LITE 7P",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "NBS PANEL",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "DRUGS OF ABUSE : METHQUOLONINE",
          "testList": [],
          "price": "1285"
        },
        {
          "name": "EGFR(FISH)",
          "testList": [],
          "price": "7815"
        },
        {
          "name": "ERG",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "EWSR1(22Q12) GENE REARRANGEMENT",
          "testList": [],
          "price": "8560"
        },
        {
          "name": "ECHINOCOCCUS DETECTION",
          "testList": [],
          "price": "1660"
        },
        {
          "name": "ELECRTON MICROSCOPY-TISSU",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "ENTEROVIRUS REAL TIME PCR",
          "testList": [],
          "price": "6955"
        },
        {
          "name": "EOSINIPHIL CATIONIC PROTEIN, SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "EPIDERMAL(PEMPHIGUS)ANTIBODY(IFA)",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "FISH IN SEX-MISMATCHED BMT",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "FISH FOR 7Q DELETION",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "FNAC SLIDES",
          "testList": [],
          "price": "4100"
        },
        {
          "name": "FACTOR 7  ACTIVITY PROCONVERTIN",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "FOOD PANEL-FRUITS1",
          "testList": [],
          "price": "5565"
        },
        {
          "name": "FOOD PANEL-NUTS AND SEEDS",
          "testList": [],
          "price": "5565"
        },
        {
          "name": "FOOD PANEL MEATS",
          "testList": [],
          "price": "3425"
        },
        {
          "name": "FOOD PANEL-FRUITS2",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "FOOD PANEL-VEGETABLES",
          "testList": [],
          "price": "6155"
        },
        {
          "name": "FOOD PANEL-PULSES",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "FOOD PANEL-SEAFOOD",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "FRAGILE-X , CHROMO ANALYSIS",
          "testList": [],
          "price": "7815"
        },
        {
          "name": "GATA-3",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "GCV RESISTANCE BY SEQUENCING",
          "testList": [],
          "price": "6850"
        },
        {
          "name": "GALACTOSEMIA GENE MUTATIONS",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "GARDNERELLA VAGINALIS CULTURE",
          "testList": [],
          "price": "1300"
        },
        {
          "name": "GLUCOSE CHALLENGE TEST",
          "testList": [],
          "price": "90"
        },
        {
          "name": "GLUCOSE-6-PHOSPHATE DEHYDROGENASE (G6PD)",
          "testList": [],
          "price": "570"
        },
        {
          "name": "GLYCIPHAN 3",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "GLYCOPHORIN A(ERYTHROID)",
          "testList": [],
          "price": "2785"
        },
        {
          "name": "GRASS POLLENS PANEL",
          "testList": [],
          "price": "5565"
        },
        {
          "name": "GROWTH HORMONE STIMULATION TEST, SERUM",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "HAV RNA PCR",
          "testList": [],
          "price": "4175"
        },
        {
          "name": "HBME-1",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "HBV BASAL CORE PROMOTER MUTATION",
          "testList": [],
          "price": "4710"
        },
        {
          "name": "HBV GENOTYPING BY PCR",
          "testList": [],
          "price": "8025"
        },
        {
          "name": "HBV PRECORE MUTATION",
          "testList": [],
          "price": "4710"
        },
        {
          "name": "HEPATITIS B SURFACE ANTIBODIES(QUANTITATIVE),SER",
          "testList": [],
          "price": "1150"
        },
        {
          "name": "HEPATITIS B VIRUS CORE IGM ANTIBODIES",
          "testList": [],
          "price": "1150"
        },
        {
          "name": "HIV ANTIBODIES , SERUM",
          "testList": [],
          "price": "670"
        },
        {
          "name": "HIV EARLY SCREEN , SERUM",
          "testList": [],
          "price": "3200"
        },
        {
          "name": "HLA (A , B , C)",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "HLA-DP LOCUS",
          "testList": [],
          "price": "8560"
        },
        {
          "name": "HLA-DR AND DQ LOCI",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "HLA  A , B , C , DR , DQ LOCI",
          "testList": [],
          "price": "27820"
        },
        {
          "name": "HLA B*5701 GENOTYPING ABACAVIR HHYPERSENSETIVITY , B",
          "testList": [],
          "price": "6100"
        },
        {
          "name": "HLA DQ AND DRLOCI TYPING",
          "testList": [],
          "price": "11235"
        },
        {
          "name": "HLA DQ TYPING",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "LA DR LOCUS",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "BONE MARROW TREPHINE BIOPSY",
          "testList": [],
          "price": "9095"
        },
        {
          "name": "BRINJAL(SOLENUM MELONGE) , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "C-MYC(8Q24) GENE REARRANGEMENT",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "C. PIPTHERIA TOXIN A AND B PCR",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "CD10(CALLA)",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "CD16+56-PERSENTS+ABSOLUTE COUNTS",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "CD19/KAPPA PERSENT",
          "testList": [],
          "price": "2355"
        },
        {
          "name": "CD19/TDT PERSENT",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "CD23-PERSENT",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "CD45-PERSENT",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "CD5-PERSENT",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "CSF INDIA INKPREPARTION",
          "testList": [],
          "price": "410"
        },
        {
          "name": "CYFRA 21-1",
          "testList": [],
          "price": "3425"
        },
        {
          "name": "CYP2C19 GENOTYPING",
          "testList": [],
          "price": "7170"
        },
        {
          "name": "CYP3A4*22 GENOTYPING",
          "testList": [],
          "price": "5675"
        },
        {
          "name": "CAMPYLOBACTOR SPECIES CULTURE",
          "testList": [],
          "price": "1100"
        },
        {
          "name": "CARDIAC ENZYME PANEL , SERUM",
          "testList": [],
          "price": "1340"
        },
        {
          "name": "CELIAC TISSU BIOPSY PLUS",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "CHIMERISM POST-TRANSPLANTATION",
          "testList": [],
          "price": "6850"
        },
        {
          "name": "CHIMERISM PRE-TRANSPLANTATION",
          "testList": [],
          "price": "13270"
        },
        {
          "name": "CHLAMYDIA PNEUMONIAE ANTIBODIES , SERUM",
          "testList": [],
          "price": "4815"
        },
        {
          "name": "CHLAMYDIA PNEUMONIAE IgM  ANTIBODIES",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "CHROMIUM , SPOT URINE",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "CLASS 1 ANTIBODY DETECTION(PRA%)",
          "testList": [],
          "price": "13375"
        },
        {
          "name": "CLASS 2 ANTIBODY DETECTION(PRA%)",
          "testList": [],
          "price": "13375"
        },
        {
          "name": "CLOSTRIDIUM TOXIN A/B",
          "testList": [],
          "price": "2200"
        },
        {
          "name": "COBALT RANDOM URINE",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "COBALT , SERUM",
          "testList": [],
          "price": "2890"
        },
        {
          "name": "COMMON MUTATION PANEL",
          "testList": [],
          "price": "13910"
        },
        {
          "name": "COXSACKIE ANTIBODY-IgG , SERUM",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "COXSACKIE ANTIBODY-IgM , SERUM",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "CRYPTOCOCCUS NEOFORMANS DNA DETECTOR",
          "testList": [],
          "price": "8135"
        },
        {
          "name": "CRYPTOSPORIDIUM  ANTIGEN DETECTION",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "CULTURE AND IDENTIFICATION -YEAST",
          "testList": [],
          "price": "1100"
        },
        {
          "name": "CULTURE AND SENSITIVITY : THROAT SWAB",
          "testList": [],
          "price": "1100"
        },
        {
          "name": "CYSTIS FIBROSIS EXTENDED MUTATION PANEL",
          "testList": [],
          "price": "32100"
        },
        {
          "name": "CYSTICERCUS IgG antibodies",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "CYSTICERCUS IgG antibodies",
          "testList": [],
          "price": "2140"
        },
        {
          "name": "CYTOCHEMISTRY PANEL FOR LEUKEMIAS",
          "testList": [],
          "price": "2465"
        },
        {
          "name": "DBS 17 OHP>1 MONTH",
          "testList": [],
          "price": "570"
        },
        {
          "name": "DBS CONGENTIAL ADRENAL HYPERPLASIA>1 MONTH",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "DOG-1",
          "testList": [],
          "price": "2700"
        },
        {
          "name": "DRPLA GENE ANALYSIS",
          "testList": [],
          "price": "5620"
        },
        {
          "name": "DELETION 13Q BY FISH",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "DIABETA PROFILE",
          "testList": [],
          "price": "1660"
        },
        {
          "name": "DIGEORGE SYNDROME 22Q11.2 DELETION , FISH",
          "testList": [],
          "price": "6745"
        },
        {
          "name": "DOPAMINE , ELISA , 24HRS PROTEIN",
          "testList": [],
          "price": "4700"
        },
        {
          "name": "DRUGS OF ABUSE-6 DRUGS",
          "testList": [],
          "price": "3320"
        },
        {
          "name": "ALLERGEN -HOUSE DUST GREER , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN -JOHNSON GRASS , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-MANGO  , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-MEADOW FESCUE , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-MELON , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-MOLD PANEL , SERUM",
          "testList": [],
          "price": "4495"
        },
        {
          "name": "ALLERGEN-MOSQUITO , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-MOUSE EPTHELIUM , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-MUGWORT , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-ONION , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-PEACH , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-PENICILLIUM , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-PINEAPPLE , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-PISTACHIO , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-POTATO , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-SESAME SEED , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-TIMOTHY GRASS , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-TOMATO , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-WALNUT , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-ASPIRIN , SERUM",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "ALLERGEN-CEPHALOSPORIN , SERUM",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "ALLERGEN-DICLOFENAC , SERUM",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "ALLERGEN-LENTIL , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-LIDOCAINE , SERUM",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "ALLLERGEN-CIPROFLOXACIN",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "ALLERGEN-PARACETAMOL",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "ALLERGEN-OLIVE",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALPHA-2 MICROGLOBULIN",
          "testList": [],
          "price": "3745"
        },
        {
          "name": "ALPHA-1-ANTITRYPSIN GENOTYPING",
          "testList": [],
          "price": "7490"
        },
        {
          "name": "ALUMINIUM , RANDOM URINE",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AMINO ACIDS , RANDOM URINE",
          "testList": [],
          "price": "8830"
        },
        {
          "name": "AMINOLEVULINIC ACID , URINE 24 HRS",
          "testList": [],
          "price": "3320"
        },
        {
          "name": "AMYLOID A (SERUM , NEPHELOMETRY)",
          "testList": [],
          "price": "4495"
        },
        {
          "name": "ANAEROBIC BACTERIA IDENTIFICATION",
          "testList": [],
          "price": "2730"
        },
        {
          "name": "ANGELMAN SYNDROME-MS PCR",
          "testList": [],
          "price": "8025"
        },
        {
          "name": "ANIMAL DANDER PANEL",
          "testList": [],
          "price": "3000"
        },
        {
          "name": "ANNEXIN-1",
          "testList": [],
          "price": "2250"
        },
        {
          "name": "ANTENATAL SCREEN",
          "testList": [],
          "price": "1395"
        },
        {
          "name": "ANTENATALL PANEL",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "ANTI-BP 180",
          "testList": [],
          "price": "4710"
        },
        {
          "name": "ANTI-BP 230",
          "testList": [],
          "price": "4710"
        },
        {
          "name": "APOLIPOPROTEIN E GENOTYPING",
          "testList": [],
          "price": "25680"
        },
        {
          "name": "ARSENIC , RANDOM URINE",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "ASPERGILLUS FUMLIGATUS DNA DETECTION",
          "testList": [],
          "price": "8025"
        },
        {
          "name": "BCL 2(18q21) GENE REARRANGEMENT",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "BCL 2(18Q21) GENE REARRANGEMENT",
          "testList": [],
          "price": "6420"
        },
        {
          "name": "BCR/ABL BREAKPOINT ANALYSIS",
          "testList": [],
          "price": "5350"
        },
        {
          "name": "BIOFIRE PNEUMONIA PANEL PLUS-LRTI",
          "testList": [],
          "price": "18000"
        },
        {
          "name": "BIOFIRE BLOOD PANEL",
          "testList": [],
          "price": "16000"
        },
        {
          "name": "BIOFIRE CSF PANEL-MENINGITIS",
          "testList": [],
          "price": "16000"
        },
        {
          "name": "BIOFIRE GI PANEL",
          "testList": [],
          "price": "16000"
        },
        {
          "name": "BIOFIRE RESPIRATORY PANEL-URTI",
          "testList": [],
          "price": "16000"
        },
        {
          "name": "BIOPSY(PHOTO)-IFA",
          "testList": [],
          "price": "485"
        },
        {
          "name": "BIOPSY(PHOTO)-SINGLE(HP)",
          "testList": [],
          "price": "195"
        },
        {
          "name": "BIOTINIDASE(BIOT)>1 MONTH",
          "testList": [],
          "price": "570"
        },
        {
          "name": "BODY FLUID FOR CYTOLOGY",
          "testList": [],
          "price": "1400"
        },
        {
          "name": "1P/19Q(FISH)",
          "testList": [],
          "price": "16050"
        },
        {
          "name": "ACTN 3 GENOTYPING",
          "testList": [],
          "price": "4280"
        },
        {
          "name": "AFB SMEAR AND CULTURE",
          "testList": [],
          "price": "2100"
        },
        {
          "name": "AFB SUSC. AMIKACIN",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSC. ETHAMBUTOL",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSC. ISONIAZID",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSC. LEVOFLOXACIN",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSC. OFLOXACIN",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSC. REFAMPICIN",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSC. KANAMYCIN",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSCEPTIBILITY CLOFAZIMINE",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSCEPTIBILITY LINEZOLID",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFB SUSCEPTIBILITY MOXIFLOXACIN",
          "testList": [],
          "price": "3210"
        },
        {
          "name": "AFP FROM AMNIOTIC FLUID",
          "testList": [],
          "price": "900"
        },
        {
          "name": "ALLERGEN -FOOD PANEL 3",
          "testList": [],
          "price": "4495"
        },
        {
          "name": "AML-ETO BY FISH",
          "testList": [],
          "price": "5995"
        },
        {
          "name": "ANTI-IDH1(H09)",
          "testList": [],
          "price": "2675"
        },
        {
          "name": "ASKA-SKELETAL(STRIATED) MUSCLE ANTIBODY",
          "testList": [],
          "price": "4070"
        },
        {
          "name": "ABS TO EXTRACTABLE NUCLEAR AG:JO-1 , SERUM",
          "testList": [],
          "price": "1605"
        },
        {
          "name": "ABSOLUTE NEUTROPHIL COUNT , BLOOD",
          "testList": [],
          "price": "225"
        },
        {
          "name": "ACID PHOSPHATASE",
          "testList": [],
          "price": "430"
        },
        {
          "name": "ACUTE HEPATITIS VIRUS-EVALUATION -3",
          "testList": [],
          "price": "5885"
        },
        {
          "name": "ACUTE PROMYELOCYTIC LEUKEMIA T",
          "testList": [],
          "price": "5140"
        },
        {
          "name": "ADENO VIRUS QUANTITATIVE",
          "testList": [],
          "price": "7815"
        },
        {
          "name": "ADRENALIN , URINE",
          "testList": [],
          "price": "3425"
        },
        {
          "name": "ALKALINE PHOSPHATASE ISOENZYMES",
          "testList": [],
          "price": "8560"
        },
        {
          "name": "ALLERGEN-ACACIA , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-ALMOND , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-ALTERNARIA TENUIS , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-APPLE , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-BAKER'S YEAST , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-BANANA , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-BERMUDA GRASS , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-BIRCH , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-CABBAGE , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-CANDIDA ALBICANS , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-CASEIN , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-CASHEW , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-CAT DANDER/EPITHELIUM , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-CAULIFLOWER , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-CHEESE CHEDDAR , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-CLADOSPORIUM HERBARUM , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-COCKROACH , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-COCOA , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-COCONUT , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-COFFEE , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-COMMON RAGWEED , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-CORN , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-COW DANDER , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN -DERMATOPHAGOIDES PTERNYSSINUS",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-DOG DANDER , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-ENGLISH PLANTAIN , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-FOOD PANEL 2",
          "testList": [],
          "price": "3960"
        },
        {
          "name": "ALLERGEN-GARLIC , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-GREEN PEA , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-GREENPEPPER , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-HOLLISTERSTIER LABS HOUSE DUST",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-HONEY BEE VENOM , SERUM",
          "testList": [],
          "price": "1500"
        },
        {
          "name": "ALLERGEN-HORSE DANDER , SERUM",
          "testList": [],
          "price": "1500"
        }
      
]
export default IndividualTests;